import React, {useEffect, useState, useRef} from "react";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {checkAuthToken, checkValue, refreshPage, navToAdminPanel, navToGreencardCompaniesPage, checkRequstStatus} from "../../../utils/utilsGeneral";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {IGreencardCashback} from "../../../typings/IDataOffers";
import Switch from "react-switch";

const EditGreencardCompany = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID, productCode, companyID, greencardID} = useParams<{
        id: string;
        externalID: string; 
        productCode: string; 
        companyID: string; 
        greencardID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);
    const [editGreencardCompanyData, setGreencardCompanyDataEdited] = useState<IGreencardCashback>({
        id: 0,
        name: "",
        companyID: 0,
        productCode: 0,
        isCashbackEnabled: false,
        isHidden: false,
        cashbackPercentageValue: 0
    });
    const [greencardCompanyErrorMsg, setGreencardCompanyErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [greencardCompanyName, setGreencardCompanyName] = useState("");
    const [isCashback, setCashback] = useState(false);
    const percentageInput = useRef<HTMLInputElement>(null);
    const [isCompanyHidden, setCompanyHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchGreencardCompany = async () => {
            try {
                const res = await dbAxiosInstance.get(`/greencard/company/${encodeURIComponent(productCode)}/${encodeURIComponent(companyID)}/${encodeURIComponent(greencardID)}`);
                const dbGreencardData = res.data;

                if (dbGreencardData) {
                    if (dbGreencardData.length > 0) {
                        setGreencardCompanyDataEdited({id: dbGreencardData[0].id, name: dbGreencardData[0].name, companyID: dbGreencardData[0].company_id, productCode: dbGreencardData[0].product_code, 
                                                        isCashbackEnabled: dbGreencardData[0].is_cashback_enabled, isHidden: dbGreencardData[0].is_hidden, cashbackPercentageValue: dbGreencardData[0].cashback_percentage});
                        setGreencardCompanyName(dbGreencardData[0].name);
                        setCashback(dbGreencardData[0].is_cashback_enabled);
                        setCompanyHidden(dbGreencardData[0].is_hidden);
                        setGreencardCompanyErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                        setGreencardCompanyName("");
                        setCashback(false);
                        setCompanyHidden(false);
                        setGreencardCompanyErrorMsg("Немає такої страхової компанії зеленої карти.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                    setGreencardCompanyName("");
                    setCashback(false);
                    setCompanyHidden(false);
                    setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                setGreencardCompanyName("");
                setCashback(false);
                setCompanyHidden(false);
                setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchGreencardCompany();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const editCompanyNameChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, name: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editCompanyIDChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, companyID: checkValue(event.target.value)}));
    };

    const editProductCodeChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, productCode: checkValue(event.target.value)}));
    };

    const handleCashbackStateChange = (isOn) => {
        setCashback(isOn);
        setGreencardCompanyDataEdited((prev) => ({...prev, isCashbackEnabled: isOn}));

        if (isOn) {
            setTimeout(() => {
                if (percentageInput.current) {
                    percentageInput.current.focus();
                    percentageInput.current.select();
                }
            }, 0);
        } else {
            if (percentageInput.current) {
                percentageInput.current.value = "0";
                setGreencardCompanyDataEdited((prev) => ({...prev, cashbackPercentageValue: 0}));
            }
        }
    };

    const handleCashbackPercentageChange = (event) => {
        const percentage = event.target.value;

        if (percentage >= 0 && percentage <= 100) {
            setGreencardCompanyDataEdited((prev) => ({...prev, cashbackPercentageValue: checkValue(percentage)}));
        }
    };

    const handleHiddenStateChange = (isCompanyHidden) => {
        setCompanyHidden(isCompanyHidden);
        setGreencardCompanyDataEdited((prev) => ({...prev, isHidden: isCompanyHidden}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editGreencardCompanyData.name.toString().trim().length > 0 && editGreencardCompanyData.companyID > 0 && editGreencardCompanyData.productCode > 0) {
            try {
                const res = await dbAxiosInstance.put(`/greencard/edit-company/${encodeURIComponent(greencardID)}`, editGreencardCompanyData);
                const editStatus = res.status;
                dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/greencard/company/${productCode}/${companyID}/${greencardID}/item/edited`, state: {
                    isSuccess: checkRequstStatus(editStatus),
                    status: editStatus,
                    successMsg: res.data,
                    errorMsg: ""
                }});
                setGreencardCompanyErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setGreencardCompanyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setGreencardCompanyErrorMsg("Будь ласка, заповніть усі поля, щоб оновити страхову компанію зеленої карти!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
        setCashback(false);
        setCompanyHidden(false);
        setGreencardCompanyErrorMsg("");
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати страхову компанію &#8212; {greencardCompanyName}</h1>
                        <div className="edit-dashboard-item-form">
                            <input type="text" name="name" placeholder="Назва" onChange={editCompanyNameChange} value={editGreencardCompanyData.name} />
                            <input type="number" name="companyID" placeholder="Ідентифікатор компанії" onChange={editCompanyIDChange} value={editGreencardCompanyData.companyID} />
                            <input type="number" name="productCode" placeholder="Код продукту" onChange={editProductCodeChange} value={editGreencardCompanyData.productCode} />
                            <div className="switch-container">
                                <label>
                                    <Switch onChange={handleCashbackStateChange} checked={isCashback} onColor="#1BA876" />
                                    <div className="switch-text">Включити кешбек</div>
                                </label>
                            </div>
                            <div className="cashback-percentage-container">
                                <input type="number" name="cashbackPercentage" id="cashback-percentage-field" placeholder="0" onChange={handleCashbackPercentageChange} value={editGreencardCompanyData.cashbackPercentageValue} readOnly={!isCashback} ref={percentageInput} />
                                <i className="fa-solid fa-percent percentage-icon"></i>
                            </div>
                            <div className="switch-container">
                                <label>
                                    <Switch onChange={handleHiddenStateChange} checked={isCompanyHidden} onColor="#1BA876" />
                                    <div className="switch-text">Є прихованою</div>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardHistory, id, externalID)}>Зелена карта</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && greencardCompanyErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{greencardCompanyErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditGreencardCompany;