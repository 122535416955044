import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams, useLocation, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {checkAuthToken, getInsuranceType, navToAdminPanel, navToCustomersEpolicyPage, navToCustomersGreencardPage, navToCustomersPage} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import _ from "lodash";

const EditedCustomerPage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID, insuranceType, phoneNumber, customerID} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
        phoneNumber: string; 
        customerID: string; 
    }>();
    const customerLocation = useLocation<{
        isSuccess: boolean;
        successMsg: string;
        errorMsg: string;
    }>();
    const {state} = customerLocation;
    const [authInProgress, setAuthInProgress] = useState(false);
    const currentInsuranceType = getInsuranceType(insuranceType);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken() && !_.isEmpty(state)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        if (state?.isSuccess) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
        } else {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const backToViewCustomer = () => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/customer/${insuranceType}/${phoneNumber}/${customerID}/view`});
    };
    
    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                            <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; 
                            <CustomTooltip msg={`Перейти до ${currentInsuranceType.label}`}><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers/${currentInsuranceType.value}`}}>{currentInsuranceType.label}</Link></CustomTooltip> &laquo; {phoneNumber}</h1>
                        {!hideSuccessNotification && state?.successMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{state.successMsg}</div>}
                        {!hideErrorNotification && state?.errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{state.errorMsg}</div>}
                        <div>
                            <button type="button" className="admin-btn" onClick={backToViewCustomer}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardHistory, id, externalID)}>Клієнти</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardHistory, id, externalID)}>Клієнти (Автоцивілка)</button>
                            <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardHistory, id, externalID)}>Клієнти (Зелена карта)</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditedCustomerPage;