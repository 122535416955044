import axios from "axios";
import config from "../config";
import {getTokenFetch} from "../redux-data/token/tokenReducer";

export const instance = axios.create({
  baseURL: config.euaBaseUrl
});

export const configEuaAxios = (store: any) => {
  instance.interceptors.request.use((value) => {
    // FIXME: Commented
    // let token: any = getToken(store.getState());
    //
    // token = token
    //   ? {
    //       'X-User-Token': token,
    //       Authorization: `${token}`,
    //     }
    //  : {};

    const token = {"Authorization": localStorage.getItem("token")};
    
    (value.headers as any) = {
      ...value.headers,
      "Access-Control-Allow-Origin": "*",
      Accept: "application/vnd.cloud.v1+json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*, Authorization",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
      ...token,
    };

    return value;
  });

  instance.interceptors.response.use(
    async (value: any) => {
      const {data = {}}: any = value;
      const {httpStatusCode} = data;

      if (httpStatusCode === 401) {
          return handleError(value);
      }

      return value;
    },
    async (error) => {
      const {response} = error;

      if (response && response.status === 401) {
          return handleError(response);
      }

      throw error;
    },
  );
  const handleError = async (response: any) => {
    await store.dispatch(await getTokenFetch());
    // FIXME: Commented
    // const config = response.config;
    // config.headers['X-User-Token'] = getToken(store.getState());
    // config.headers['Authorization'] = getToken(store.getState());
    return axios({
      ...response.config,
    });
  };
};
