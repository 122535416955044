import React, {useRef, useState, useEffect} from "react";
import {generateClassName} from "../../utils/gcm";
import validation from "../../utils/validation";

interface ICodeBlockProps {
  count: number;
  name: string;
  errors: any;
}

const CodeBlock = React.memo(
  React.forwardRef(({count, name, errors}: ICodeBlockProps, register: any) => {
    const [arr] = useState([] as any[]);
    const [codeChars, setCodeChars] = useState(Array.from({length: count}, (value) => ""));
    const isError = !!errors[name];

    useEffect(() => {
      if (arr[0] && arr[0].current) {
          arr[0].current.focus();
      }
    }, []);

    const handleKeyUp = (e) => {
      const index = +e.target.dataset.index;
      const length = e.target.value.length;

      if (e.which !== 8 && e.which !== 46 && length === 1 && index < (count - 1)) {
          arr[index + 1].current.focus();
      }
    };

    const handleKeyDown = (e) => {
      const index = +e.target.dataset.index;
      const length = e.target.value.length;

      if ((e.which === 8 || e.which === 46) && index > 0) {
          if (length === 0) {
              arr[index - 1].current.focus();
          }
      } else if ((e.which === 8 || e.which === 46) && index === 0 && length === 1) {
          arr[index].current.focus();
      } else if (length > 0 && e.which !== 13 && e.which !== 86) {
          e.preventDefault();
      }
    };

    const handlePaste = (event) => {
      pasteCodeFromClipboard(event);
    };

    const pasteCodeFromClipboard = (event) => {
      event.preventDefault();
      const codePasted = event.clipboardData.getData("text/plain");

      if (!isNaN(parseInt(codePasted))) {
          setCodeChars(codePasted.split("").slice(0, codeChars.length));
          arr[count - 1].current.focus();
      }
    };

    const handleInput = (event) => {
      const index = +event.target.dataset.index;
      setCodeChars([...codeChars.slice(0, index), event.target.value, ...codeChars.slice(index + 1)]);
    };

    return (
      <div
        className={generateClassName("restore-code_block", {
          "error-form-border": isError,
        })}>
        {codeChars.map((value, index) => {
            const refI: any = useRef(null);
            arr.push(refI);
            return (
              <input
                type="number"
                className="form-control"
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                data-index={index}
                key={index}
                name={`${name}[${index}]`}
                maxLength={1}
                value={value}
                onInput={handleInput}
                ref={(el) => {
                  refI.current = el;
                  register(el, validation.required);
                }}
              />
            );
          })}
        {isError && <p className="error-block-code">{validation.required.required}</p>}
      </div>
    );
  }),
);

export default CodeBlock;
