import React, {useState} from "react";
import useForm from "react-hook-form";
import CustomField from "../../MainPage/CustomField";
import webConfig from "../../../config";
import CustomTooltip from "./CustomTooltip";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {clearCardNumber, removeWhitespace} from "../../../utils/utilsGeneral";
import creditCardType from "credit-card-type";

const CashbackCardComponent = ({id}) => {
    const [cardNumber, setCardNumber] = useState("");
    const [cardType, setCardType] = useState("");
    const [cardSuccessMsg, setCardSuccessMsg] = useState("");
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [isCardAdded, setCardAdded] = useState(false);
    const {register, errors, handleSubmit} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange",
        nativeValidation: false
    });

    const handleCardNumberChange = (event) => {
        const clearNum = clearCardNumber(event.target.value);
        event.target.value = `${clearNum.slice(0, 4)} ${clearNum.slice(4, 8)} ${clearNum.slice(8, 12)} ${clearNum.slice(12, 19)}`.trim();
        const type = creditCardType(event.target.value)[0]?.type;

        if (type && event.target.value.length > 0) {
            setCardType(type);
        } else {
            setCardType("");
        }

        setCardNumber(event.target.value);
    };

    const editReportCardNumber = async (id, cardNumber) => {
        try {
            const res = await dbAxiosInstance.put(`/update-report/card-number/${encodeURIComponent(id)}`, {card: cardNumber});
            setCardAdded(true);
            setSuccessNotificationHidden(false);
            setCardSuccessMsg(res.data);
        } catch (error: any) {
            setCardAdded(false);
            setCardSuccessMsg("");
            setSuccessNotificationHidden(true);
        }
    };

    const handleCardSubmit = (data) => {
        if (data) {
            const cardNumber = removeWhitespace(data.card);

            if (cardNumber.length === Number(webConfig.customerCardNumLength)) {
                editReportCardNumber(id, cardNumber);
            } else {
                setCardAdded(false);
                setCardSuccessMsg("");
                setSuccessNotificationHidden(true);
            }
        } else {
            setCardAdded(false);
            setCardSuccessMsg("");
            setSuccessNotificationHidden(true);
        }
    };

    const closeSuccessMsg = (event) => {
        setCardNumber("");
        setCardType("");
        setCardSuccessMsg("");
        setSuccessNotificationHidden(true);
    };

    return (
        <div className="cashback-card-container">
            {isCardAdded ?
                !hideSuccessNotification && cardSuccessMsg && 
                    <div className="admin-success-notification">
                        <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeSuccessMsg}></i></CustomTooltip>{cardSuccessMsg}
                    </div>
             :
                <>
                    <p className="cashback-card-msg">Вкажіть номер Вашої карти, на яку ви бажаєте отримати свій бонус.</p>
                    <div className="col-md-4">
                        <form id="card-form" onSubmit={handleSubmit(handleCardSubmit)} noValidate>
                            <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /[\d| ]{19}/,
                                        message: "Введіть вірний номер карти",
                                    },
                                    maxLength: {
                                        value: 19,
                                        message: "Введіть вірний номер карти",
                                    }
                                })}
                                errors={errors}
                                name="card"
                                label="Номер карти"
                                defaultValue={cardNumber}
                                readOnly={false}
                                placeholder="0000 0000 0000 0000"
                                onChange={handleCardNumberChange}
                            />
                            <i className={`${cardType ? `fa-brands fa-cc-${cardType}` : "fa-regular fa-credit-card"} card-icon`}></i>
                        </form>
                        <button type="submit" form="card-form" className="submit-btn"><i className="fa-regular fa-floppy-disk notification-icon"></i>Підтвердити</button>
                    </div>
                </>
            }
        </div>
    );
};

export default CashbackCardComponent;