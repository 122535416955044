import React from "react";
import Select, {components} from "react-select";
import Creatable from "react-select/creatable";
import {customStyles, customTheme} from "../Forms/common/customStyles";

interface ICustomSelectProps {
  options: any;
  handleChange: any;
  handleInputChange?: any;
  handleLoadOptions?: any;
  key?: any;
  value?: any;
  defaultValue?: any;
  placeholder?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isCreatable: boolean;
  isError?: boolean;
  isOptionStyle?: boolean;
}

const CustomSelect = ({key, value, handleChange, handleInputChange, handleLoadOptions, options, placeholder, defaultValue, isSearchable = false, isCreatable = false, isDisabled = false, isError = false, isOptionStyle = false}: ICustomSelectProps) => {
  const ControlComponent = (props) => {
      return (
          <div>
              <components.Control {...props} />
              {isError && <div className="form-group-error"><p>Це поле обов'язкове</p></div>}
          </div>
      );
  };

  return (
    isCreatable ?
      <Creatable 
          key={key}
          options={options}
          isSearchable={isSearchable}
          styles={customStyles(isError, isOptionStyle)}
          formatCreateLabel={(inputValue) => `Вибрати ${inputValue}`}
          theme={customTheme}
          onChange={handleChange}
          onInputChange={handleInputChange}
          loadOptions={handleLoadOptions}
          value={value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          noOptionsMessage={() => ""}
          loadingMessage={() => "Завантаження"}
          components={{Control: ControlComponent}}
      />
    :
      <Select
          key={key}
          options={options}
          isSearchable={isSearchable}
          styles={customStyles(isError, isOptionStyle)}
          theme={customTheme}
          onChange={handleChange}
          onInputChange={handleInputChange}
          loadOptions={handleLoadOptions}
          value={value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          noOptionsMessage={() => ""}
          loadingMessage={() => "Завантаження"}
          components={{Control: ControlComponent}}
    />
  )
};

export default CustomSelect;
