import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "react-hook-form";
import {useId} from "react-id-generator";
import {getInfoByType, getOptionsByKind, getGreenCardType} from "../../core/typeAuto";
import {useStepsContext} from "../../utils/StepsProvider";
import {hasCityZone, setData, setError} from "../../redux-data/city/cityReducer";
import {AutoComplete} from "../MainPage/AutoComplete/AutoComplete";
import {getPrivilege, getIsForeign} from "../../redux-data/insurance/insuranceSelector";
import {config} from "../../assets/config";
import {getVehicle, getVehicleData, getVehicleError, getVehicleLoading} from "../../redux-data/vehicle/vehicleReducer";
import {setOfferMark, setOfferModel} from "../../redux-data/offer/offerReducer";
import {setDataOrder} from "../../redux-data/insurance/insuranceReducer";
import CustomField from "../MainPage/CustomField";
import webConfig from "../../config";
import {RootState} from "../../redux-data";
import {areas, periods, VehicleTypeList, PeriodsTypeList, AreasTypeList} from "../../core/greenCardData";
import {setGreenCardDataOrder} from "../../redux-data/insurance/insuranceReducer";
import RegCities from "./common/RegCities";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {fetchVehicleData, validateOrderData, checkVehicleBrand, findDBVehicleModel, fetchVehicleModelByBrandID, isVehicleOtkRequired} from "../../utils/utilsGeneral";
import CustomLoader from "./common/CustomLoader";
import {IVehicle} from "../../redux-data/vehicle/vehicleTypes";
import _ from "lodash";
import CustomSelect from "../common/CustomSelect";
import {getCurrentDateTime, getMaxDate, compareOtkDate} from "../../utils/utilsDate";
import {clearUserPrivilegeType} from "../../redux-data/user/userReducer";
import {getUserPrivilegeType} from "../../redux-data/user/userSelector";

const NumberAutoForm = (props) => {
  const isCityZone = useSelector(hasCityZone);
  const vehicle = useSelector(getVehicleData);
  const isLoading = useSelector(getVehicleLoading);
  const privilege = useSelector(getPrivilege);
  const regError = useSelector(getVehicleError);
  const isForeign = useSelector(getIsForeign);
  const userPrivilegeType = useSelector(getUserPrivilegeType);
  const [vehicleEngineOptions, setVehicleEngineOptions] = useState([{}]);
  const [engineType, setEngineType] = useState("");
  const [privilegeState, setPrivilegeState] = useState(false);
  const [isPrivilegeError, setPrivilegeError] = useState(false);
  const [privilegeErrorMsg, setPrivilegeErrorMsg] = useState("");
  const privilegeCategories = webConfig.disallowedPrivilegeCategories.split(", ");
  const greenCardData = useSelector(((state: RootState) => state.insurance.greenCardDataOrder));
  const [vehicleType, setVehicleType] = useState<VehicleTypeList>(greenCardData.vehicleType);
  const [coverageArea, setCoverageArea] = useState<AreasTypeList>(greenCardData.coverageArea);
  const [period, setPeriod] = useState<PeriodsTypeList>(greenCardData.period);
  const [promocodeState, setPromocodeState] = useState(false);
  const [promocode, setPromocode] = useState("");
  const vehicleRegLocationInput = useRef<HTMLInputElement>(null);
  const [city, setCity] = useState("");
  const [touch, setTouch] = useState(false);
  const [engineTypeError, setEngineTypeError] = useState(false);
  const [periodError, setPeriodError] = useState(false);
  const [coverageAreaError, setCoverageAreaError] = useState(false);
  const [vehicleOtkDate, setVehicleOtkDate] = useState("");
  const [isVehicleValidOtkDate, setIsVehicleValidOtkDate] = useState(false);
  const [vehicleOtkDateErrorMsg, setVehicleOtkDateErrorMsg] = useState("");

  const [vehicleInfo, setVehicleInfo] = useState<IVehicle>({
    engineVolume: 0,
    year: 0,
    vin: "",
    category: "",
    kind: "",
    brand: "",
    model: "",
    modelText: "",
    stateNumber: "",
    dontHaveVIN: false,
    lastModified: "",
    autoColor: "",
    registrationPlace: {
      placeCode: 0,
      zone: 0,
      nameFull: "",
      id: 0,
      country: "",
      name: "",
      nameRus: "",
      nameFullRus: "",
      cdbMtibu: false,
      cdbMtibuCode: 0,
      lastModified: ""
    },
    isForeignReg: false
  });

  const {register, handleSubmit, errors, clearError} = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      privilege
    }
  });

  const handleChangeEngineType = (e: any) => {
    vehicle.type = e.value;
    setEngineType(e);
    validatePrivilege(privilegeState);
    validateGreenCardVehicleType(vehicle.type);
    setEngineTypeError(false);
  };

  const handlePrivilegeChange = (event) => {
    const isPrivilegeChecked = event.target.checked;
    setPrivilegeState(isPrivilegeChecked);
    validatePrivilege(isPrivilegeChecked);

    if (isPrivilegeChecked && userPrivilegeType) {
        dispatch(clearUserPrivilegeType());
    }
  };

  const handlePromocodeStateChange = (event) => {
    setPromocodeState(event.target.checked);
  };

  const validatePrivilege = (isChecked) => {
    if (isChecked && (vehicle.type === privilegeCategories[0] || vehicle.type === privilegeCategories[1])) {
        setPrivilegeError(true);
        setPrivilegeErrorMsg("Об'єм двигуна для пільговиків не може перевищувати 2500");
    } else {
        setPrivilegeError(false);
        setPrivilegeErrorMsg(""); 
    }
  };

  const handleProcodeChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    setPromocode(event.target.value);
  };

  const [checkboxId] = useId(1, "checkbox");
  const [formId] = useId(1, "form");

  const dispatch = useDispatch();
  const {goTo} = useStepsContext() as any;

  const onSubmit = async (data: any) => {
    if (!vehicle || (vehicle && !vehicle.type)) {
        await dispatch(getVehicle(data.register_auto));
    } else {
        addVehicleData(vehicleInfo);

        if (props.insuranceType === "epolicy") {
            if ((vehicle.registrationPlace?.id && vehicle.registrationPlace?.name && vehicle.type && isVehicleOtkRequired(vehicle.kind, vehicle.type) && vehicleOtkDate) || (city.length > 0 && isCityZone && vehicle.type && isVehicleOtkRequired(vehicle.kind, vehicle.type) && vehicleOtkDate)) {
                dispatch(setDataOrder(data));
                goTo(2);
            } else if ((vehicle.registrationPlace?.id && vehicle.registrationPlace?.name && vehicle.type && !isVehicleOtkRequired(vehicle.kind, vehicle.type) && !vehicleOtkDate) || (city.length > 0 && isCityZone && vehicle.type && !isVehicleOtkRequired(vehicle.kind, vehicle.type) && !vehicleOtkDate)) {
                dispatch(setDataOrder(data));
                goTo(2);
            } else {
                if (!vehicle.type) {
                    setEngineTypeError(true);
                }

                if (vehicleRegLocationInput.current) {
                    vehicleRegLocationInput.current.focus();

                    if (vehicleRegLocationInput.current.value.length === 0) {
                        setCity("");
                        setTouch(false);
                        dispatch(setError({
                          message: "Це поле обов\'язкове"
                        }));
                    }
                }
            }
        } else if (props.insuranceType === "greencard") {
            if (vehicle.registrationPlace?.id && vehicle.registrationPlace?.name && vehicleType && coverageArea && period) {
                dispatch(setGreenCardDataOrder({
                  ...data,
                  vehicleType,
                  coverageArea,
                  period
                }));
                goTo(2);
            } else if (city.length > 0 && isCityZone && vehicleType && coverageArea && period) {
                dispatch(setGreenCardDataOrder({
                  ...data,
                  vehicleType,
                  coverageArea,
                  period
                }));
                goTo(2);
            } else {
                if (!vehicleType) {
                    setEngineTypeError(true);
                }

                if (!period) {
                    setPeriodError(true);
                }

                if (!coverageArea) {
                    setCoverageAreaError(true);
                }

                if (vehicleRegLocationInput.current) {
                    vehicleRegLocationInput.current.focus();

                    if (vehicleRegLocationInput.current.value.length === 0) {
                        setTouch(false);
                        dispatch(setError({
                          message: "Це поле обов\'язкове"
                        }));
                    }
                }
            }
        }
    }
  };

  const addVehicleData = async (currentVehicle) => {
    await dbAxiosInstance.post("/add-vehicle/number", currentVehicle);
  };

  const handleChangeCoverageArea = (e: any) => {
      setCoverageArea(e);
      setCoverageAreaError(false);
  };

  const handleChangePeriod = (e: any) => {
      setPeriod(e);
      setPeriodError(false);
  };

  useEffect(() => {
    const handle = async () => {
      const {modelName, brandName, name, id} = vehicle;

      if (brandName && modelName && name && id) {
          const vehicleBrand = await checkVehicleBrand(name);

          if (vehicleBrand) {
              dispatch(setOfferMark(vehicleBrand));
              const models = await fetchVehicleModelByBrandID(vehicleBrand.id);
              const findModel = findDBVehicleModel(models, modelName);

              if (findModel) {
                  dispatch(setOfferModel(findModel));
              } else {
                  const vehicleData = fetchVehicleData(vehicleBrand.name, modelName);
                  dispatch(setOfferModel(vehicleData));
              }
          } else {
              const dbVehicleData = fetchVehicleData(name, modelName);              
              dispatch(setOfferMark(dbVehicleData));
              dispatch(setOfferModel(dbVehicleData));
          }
      }
    };

    const validateVehicleType = (kind) => {
      setVehicleEngineOptions(getOptionsByKind(kind));
    };

    if (vehicle) {
        handle();

        if (vehicle.type) {
            validateGreenCardVehicleType(vehicle.type);
        } else {
            validateVehicleType(vehicle.kind);
        }

        if (vehicle.registrationPlace?.id && vehicle.registrationPlace?.name) {
            dispatch(setData(vehicle.registrationPlace));
        }

        setVehicleInfo((prev) => ({...prev, engineVolume: vehicle.engineVolume, year: vehicle.year, vin: vehicle.vin, category: vehicle.type, kind: vehicle.kind, brand: vehicle.name, model: vehicle.modelName, modelText: vehicle.brandName, 
          stateNumber: vehicle.regNumber, dontHaveVIN: vehicle.dontHaveBodyNumber, lastModified: vehicle.lastModified ?? vehicle.registrationPlace?.lastModified ?? getCurrentDateTime(), autoColor: vehicle.autoColor, 
          isForeignReg: validateOrderData(isForeign), registrationPlace: {
            placeCode: vehicle.registrationPlace?.placeCode,
            zone: vehicle.registrationPlace?.zone,
            nameFull: vehicle.registrationPlace?.nameFull,
            id: vehicle.registrationPlace?.id,
            country: vehicle.registrationPlace?.country,
            name: vehicle.registrationPlace?.name,
            nameRus: vehicle.registrationPlace?.nameRus,
            nameFullRus: vehicle.registrationPlace?.nameFullRus,
            cdbMtibu: vehicle.registrationPlace?.cdbMtibu,
            cdbMtibuCode: vehicle.registrationPlace?.cdbMtibuCode,
            lastModified: vehicle.registrationPlace?.lastModified
          }}
        ));
    }

    if (vehicleRegLocationInput.current) {
        vehicleRegLocationInput.current.focus();
    }
  }, [vehicle, dispatch]);

  const validateGreenCardVehicleType = (type) => {
    const greenCardType = getGreenCardType(type);
    setVehicleType(greenCardType as VehicleTypeList);
  };

  const setVechicleRegCity = async (event) => {
    const dataSuggestion = event.target.dataset.suggestion;

    if (vehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
        vehicleRegLocationInput.current.value = event.target.value;
        setCity(vehicleRegLocationInput.current.value);
        setTouch(true);
        const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
        dispatch(setData(place.data[0]));
    } else {
        setCity("");
        setTouch(false);
    }
  };

  const handleVechileRegContent = (event, {newValue, method}) => {
    setCity(newValue);
    
    if (newValue !== "") {
        setTouch(true);
    } else {
        setTouch(false);
        dispatch(setError({
          message: "Це поле обов\'язкове"
        }));
    }
  };

  const handleVechileRegContentBlur = (event, {newValue, method}) => {
    setTouch(true);
  };

  const handleOtkDateChange = (name, date) => {
    const isValidOtkDate = compareOtkDate(date);

    if (isValidOtkDate) {
        setVehicleOtkDate(date);
        setIsVehicleValidOtkDate(false);
        setVehicleOtkDateErrorMsg("");
    } else {
        setVehicleOtkDate("");
        setIsVehicleValidOtkDate(true);
        setVehicleOtkDateErrorMsg("Ви ввели невірну дату Обов'язкового Технічного Контролю (ОТК)!");
    }

    clearError(name);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId} noValidate>
      {(!vehicle || (vehicle && !vehicle.id)) && (
        <>
          <div className="form-group">
            <CustomField
              className="vehicle-number"
              register={register({
                required: true,
                pattern: {
                  value: /[A-zА-я-І-і-Ї-ї0-9]/,
                  message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                }
              })}
              errors={errors}
              name="register_auto"
              label=""
              placeholder="АА0000АА"
              onChange={props.handleChange}
              defaultValue={props.vehicleNumber}
              autoFocus
            />
            {regError && (!vehicle || !vehicle.id) && <div className="customer-error-notification">{regError}</div>}
            <CustomLoader isSquare={true} isInfoOn={false} isLoading={isLoading} color={config.color} css={config.css} />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            Розрахувати вартість
          </button>
        </>
      )}
      {vehicle && (
        <div>
          <div className="vehicle-info">
            <p>
              Марка
              <br />
              <strong>{vehicle.brandName || "Н/Д"}</strong>
            </p>
            <p>
              Модель
              <br />
              <strong>{vehicle.modelName || "Н/Д"}</strong>
            </p>
            <p>
              Номер авто
              <br />
              <strong>{vehicle.regNumber}</strong>
            </p>
            <p>
              {vehicle.type ? getInfoByType(vehicle.type).name : "Обсяг"}
              <br />
              {
                vehicle.type ? 
                  <strong>{getInfoByType(vehicle.type).label}</strong> 
                : 
                  <CustomSelect
                    options={vehicleEngineOptions} 
                    isSearchable={false}
                    isCreatable={false}
                    handleChange={handleChangeEngineType}
                    value={engineType}
                    placeholder="Обсяг двигуна"
                    isError={engineTypeError}
                  />
              }
            </p>
          </div>
          {props.insuranceType === "greencard" ?
            <div className="greencard-form">
              <div className="form-group">
                  <CustomSelect
                    options={periods}
                    isSearchable={false}
                    handleChange={handleChangePeriod}
                    isCreatable={false}
                    value={period}
                    placeholder="Період дій договору"
                    isError={periodError}
                  />
              </div>
              <div className="form-group">
                  <CustomSelect
                    options={areas}
                    isSearchable={false}
                    handleChange={handleChangeCoverageArea}
                    isCreatable={false}
                    value={coverageArea}
                    placeholder="Територія покриття"
                    isError={coverageAreaError}
                  />
              </div>
            </div>
            :
            <div>
              <div className="checkbox-car">
                <div className="form-group form-check">
                  <input ref={register} name="privilege" id={checkboxId} type="checkbox" defaultChecked={privilegeState} onChange={handlePrivilegeChange} hidden />
                  <label htmlFor={checkboxId}>Є пільга</label>
                </div>
                <div className="form-group form-check">
                  <input ref={register} name="taxi" id="taxi" type="checkbox" hidden />
                  <label htmlFor="taxi">Таксі</label>
                </div>
                <div className="form-group form-check">
                  <input ref={register} name="promocode" id="promocode" type="checkbox" defaultChecked={promocodeState} onChange={handlePromocodeStateChange} hidden />
                  <label htmlFor="promocode">Промокод</label>
                </div>
              </div>
            {isPrivilegeError && privilegeErrorMsg && <div className="policy-error-notification">{privilegeErrorMsg}</div>}
            {promocodeState && 
              <div className="promocode-container">
                <CustomField 
                  type="text" 
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /^[A-z0-9]{10}$/,
                      message: "Промокод невірно вказаний"
                    },
                    maxLength: {
                      value: 10,
                      message: "Промокод складається з 10 символів",
                    },
                    minLength: {
                      value: 10,
                      message: "Промокод складається з 10 символів"
                    }
                  })}
                  className="promocode-input" 
                  name="promocodeInput" 
                  placeholder="Введіть Ваш промокод" 
                  errors={errors}
                  onChange={handleProcodeChange}
                  label=""
                  defaultValue={promocode}
                  autoFocus
                  />
                </div>
            }
            </div>
          }
          {!vehicle.registrationPlace?.id && !vehicle.registrationPlace?.name &&
            <>
              <AutoComplete ref={vehicleRegLocationInput} content={city} handleContent={handleVechileRegContent} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} id="policy-required-auto-number" />
              <RegCities handleVechicleRegCity={setVechicleRegCity} />
            </>
          }
          {props.insuranceType === "epolicy" && isVehicleOtkRequired(vehicle.kind, vehicle.type) &&
            <>
              <CustomField
                register={register({
                  required: "Це поле обов\'язкове"
                })}
                errors={errors}
                name="otkDate"
                label="Дата наступного ОТК"
                type="date"
                placeholder="РРРР.ММ.ДД"
                max={getMaxDate()}
                autoComplete="off"
                dateValue={vehicleOtkDate}
                onDateChange={handleOtkDateChange}
              />
              {isVehicleValidOtkDate && vehicleOtkDateErrorMsg && <div className="customer-error-notification">{vehicleOtkDateErrorMsg}</div>}
            </>
          }
          <button type="submit" className="btn btn-primary">
            Далі
          </button>
        </div>
      )}
    </form>
  );
};

export default NumberAutoForm;
