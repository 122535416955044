import React, {useState, useEffect, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentDateTime} from "../../../utils/utilsDate";
import InfoSpoiler from "../../../components/Forms/common/InfoSpoiler";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {checkValue, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {AutoComplete} from "../../../components/MainPage/AutoComplete/AutoComplete";
import RegCities from "../../../components/Forms/common/RegCities";
import Switch from "react-switch";

const AddVehiclePage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [vinCode, setVINCode] = useState("");
    const [vehicleColor, setVehicleColor] = useState("");
    const [stateNumber, setStateNumber] = useState("");
    const [category, setCategory] = useState("");
    const [kind, setKind] = useState("");
    const [isVINChecked, setVINChecked] = useState(true);
    const [regPlaceID, setRegPlaceID] = useState("");
    const [regPlaceCode, setRegPlaceCode] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [vehicleSuccessMsg, setVehicleSuccessMsg] = useState("");
    const [isVehicleAdded, setVehicleAdded] = useState(false);
    const [vehicle, setVehicle] = useState<IVehicle>({
        engineVolume: 0,
        year: 0,
        vin: "",
        category: "",
        kind: "",
        brand: "Назва - Skoda",
        model: "Octavia A7",
        modelText: "",
        stateNumber: "",
        dontHaveVIN: false,
        lastModified: "",
        autoColor: "",
        isForeignReg: false,
        registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }
    });
    const engineVolumeInput = useRef<HTMLInputElement>(null);
    const yearInput = useRef<HTMLInputElement>(null);
    const vinInput = useRef<HTMLInputElement>(null);
    const categoryInput = useRef<HTMLInputElement>(null);
    const kindInput = useRef<HTMLInputElement>(null);
    const brandInput = useRef<HTMLInputElement>(null);
    const modelInput = useRef<HTMLInputElement>(null);
    const modelTextInput = useRef<HTMLInputElement>(null);
    const stateNumberInput = useRef<HTMLInputElement>(null);
    const autoColorInput = useRef<HTMLInputElement>(null);
    const regPlaceIDInput = useRef<HTMLInputElement>(null);
    const regPlaceCodeInput = useRef<HTMLInputElement>(null);
    const vehicleRegLocationInput = useRef<HTMLInputElement>(null);
    const [city, setCity] = useState("");
    const [touch, setTouch] = useState(false);
    const [authInProgress, setAuthInProgress] = useState(false);
    const [isForeign, setForeign] = useState(false);

    const navToVehiclesPage = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/vehicles`});
    };

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const addVehicle = async (event) => {
        event.preventDefault();

        if (vehicle.engineVolume.toString().length > 0 && vehicle.year > 0 && vehicle.category.trim().length > 0 && vehicle.kind.trim().length > 0 && vehicle.brand.trim().length > 0 && vehicle.model!.trim().length > 0 && 
            vehicle.modelText!.trim().length > 0 && vehicle.stateNumber.trim().length > 0 && vehicle.lastModified.trim().length > 0) {
            try {
                const res = await dbAxiosInstance.post("/admin/add-vehicle", vehicle);
                setVehicleSuccessMsg(res.data);
                setVehicleAdded(true);
                setSuccessNotificationHidden(false);
                setVehicleErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setVehicleAdded(false);
                setVehicleSuccessMsg("");
                setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setVehicleAdded(false);
            setVehicleSuccessMsg("");
            setVehicleErrorMsg("Будь ласка, заповніть усі поля, щоб додати транспортний засіб!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
        setVehicleAdded(false);
        setVehicle((prev) => ({...prev, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, autoColor: "", registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }}));
        
        if (engineVolumeInput.current) {
            engineVolumeInput.current.value = "";
        }

        if (yearInput.current) {
            yearInput.current.value = "";
        }

        if (vinInput.current) {
            vinInput.current.value = "";
        }

        if (categoryInput.current) {
            categoryInput.current.value = "";
        }

        if (kindInput.current) {
            kindInput.current.value = "";
        }

        if (brandInput.current) {
            brandInput.current.value = "";
        }

        if (modelInput.current) {
            modelInput.current.value = "";
        }

        if (modelTextInput.current) {
            modelTextInput.current.value = "";
        }

        if (stateNumberInput.current) {
            stateNumberInput.current.value = "";
        }

        if (autoColorInput.current) {
            autoColorInput.current.value = "";
        }

        if (regPlaceIDInput.current) {
            regPlaceIDInput.current.value = "";
        }

        if (regPlaceCodeInput.current) {
            regPlaceCodeInput.current.value = "";
        }

        setVehicleColor("");
        setStateNumber("");
        setVINChecked(true);
        setVehicleAdded(false);
        setCategory("");
        setKind("");
        setVehicleSuccessMsg("");
        setCity("");
        setRegPlaceID("");
        setRegPlaceCode("");
        setForeign(false);
    };

    const handleVINChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVINCode(event.target.value);
        let isVINCodeChecked = false;

        if (event.target.value.length <= 0) {
            isVINCodeChecked = true;
        } else {
            isVINCodeChecked = false;
        }

        setVINChecked(isVINCodeChecked);
        setVehicle((prev) => ({...prev, vin: checkValue(event.target.value), dontHaveVIN: isVINCodeChecked}));
    };

    const handleDontHaveVINChange = (event) => {
        setVINChecked(event.target.checked);
    };

    const handleAutoColorChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleColor(event.target.value);
        setVehicle((prev) => ({...prev, autoColor: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = (event) => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleEngineVolumeChange = (event) => {
        setVehicle((prev) => ({...prev, engineVolume: checkValue(event.target.value)}));
    };

    const handleYearChange = (event) => {
        setVehicle((prev) => ({...prev, year: checkValue(event.target.value)}));
    };

    const handleCategoryChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setCategory(event.target.value);
        setVehicle((prev) => ({...prev, category: checkValue(event.target.value)}));
    };

    const handleKindChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setKind(event.target.value);
        setVehicle((prev) => ({...prev, kind: checkValue(event.target.value)}));
    };

    const handleBrandChange = (event) => {
        setVehicle((prev) => ({...prev, brand: checkValue(event.target.value)}));
    };

    const handleModelChange = (event) => {
        setVehicle((prev) => ({...prev, model: checkValue(event.target.value)}));
    };

    const handleStateNumberChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setStateNumber(event.target.value);
        setVehicle((prev) => ({...prev, stateNumber: checkValue(event.target.value), modelText: `${vehicle.brand} ${vehicle.model}`, lastModified: getCurrentDateTime()}));
    };

    const handleVechileRegPlace = (event, {newValue, method}) => {
        setCity(newValue);
        
        if (newValue !== "") {
            setTouch(true);
        } else {
            setTouch(false);
        }
    };

    const handleVechileRegContentBlur = (event, {newValue, method}) => {
        setTouch(true);
    };

    const setVechicleRegCity = async (event) => {
        const dataSuggestion = event.target.dataset.suggestion;

        if (vehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
            vehicleRegLocationInput.current.value = event.target.value;
            setCity(vehicleRegLocationInput.current.value);
            setTouch(true);
            const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
            const regPlace = place.data[0];
            setVehicleRegLocation(regPlace);
        } else {
            setCity("");
            setTouch(false);
        }
    };

    const handleRegPlaceSuggestion = (suggestion) => {
        setVehicleRegLocation(suggestion);
    };

    const setVehicleRegLocation = (location) => {
        if (location) {
            setRegPlaceID(location.id);
            setRegPlaceCode(location.place_code);
            setVehicle((prev) => ({...prev, registrationPlace: {
                id: location.id,
                placeCode: location.place_code,
                zone: location.zone, 
                nameFull: location.name_full, 
                country: location.country,
                name: location.name,
                nameRus: location.name_rus, 
                nameFullRus: location.name_full_rus, 
                cdbMtibu: location.cdb_mtibu,
                cdbMtibuCode: location.cdb_mtibu_code,
                lastModified: location.last_modified
            }}));
        } else {
            setRegPlaceID("");
            setRegPlaceCode("");
            setVehicle((prev) => ({...prev, registrationPlace: {
                id: 0,
                placeCode: 0,
                zone: 0, 
                nameFull: "", 
                country: "",
                name: "",
                nameRus: "", 
                nameFullRus: "", 
                cdbMtibu: false,
                cdbMtibuCode: 0,
                lastModified: ""
            }}));
        }
    };

    const handleForeignStateChange = (state) => {
        setForeign(state);
        setVehicle((prev) => ({...prev, isForeignReg: state}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                        <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                       <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            {!isVehicleAdded ?
                            <>
                                <h1>Додати транспортний засіб</h1>
                                <div className="add-dashboard-item-form">
                                    <input type="number" name="engineVolume" placeholder="Об'єм двигуна - 1598" onChange={handleEngineVolumeChange} ref={engineVolumeInput} />
                                    <input type="number" name="year" placeholder="Рік - 2021" onChange={handleYearChange} ref={yearInput} />
                                    <input type="text" name="vin" placeholder="VIN код - TVBAN5NE7LB018349" onChange={handleVINChange} ref={vinInput} value={vinCode} />
                                    <input type="text" name="category" placeholder="Категорія - B1, B2..." onChange={handleCategoryChange} ref={categoryInput} value={category} />
                                    <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про категорію ТЗ" 
                                        spoilerContent={
                                            <div className="vehicle-cat-details">
                                                <p className="font-bold">Категорія ТЗ</p>
                                                <p>Допустимі значення:</p>
                                                <ul>
                                                    <li><span className="font-bold">A1</span> - мотоцикл / моторолер - до 300 см3</li>
                                                    <li><span className="font-bold">A2</span> - мотоцикл / моторолер - більше 300 см3</li>
                                                    <li><span className="font-bold">B1</span> - легковий автомобіль - до 1600 см3</li>
                                                    <li><span className="font-bold">B2</span> - легковий автомобіль - 1601 - 2000 см3</li>
                                                    <li><span className="font-bold">B3</span> - легковий автомобіль - 2001 - 3000</li>
                                                    <li><span className="font-bold">B4</span> - легковий автомобіль - більше 3000 см3</li>
                                                    <li><span className="font-bold">B5</span> - легковий електромобіль (виключно з силовим електродвигуном, крім гібридних авто)</li>
                                                    <li><span className="font-bold">C1</span> - вантажний автомобіль - до 2т</li>
                                                    <li><span className="font-bold">C2</span> - вантажний автомобіль - більше 2т</li>
                                                    <li><span className="font-bold">D1</span> - автобус - до 20 людей</li>
                                                    <li><span className="font-bold">D2</span> - автобус - більше 20 людей</li>
                                                    <li><span className="font-bold">E</span> - причіп до вантажного авто</li>
                                                    <li><span className="font-bold">F</span> - причіп до легкового авто</li>
                                                </ul>
                                                <hr/>
                                                <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoTransferBean" target="_blank" rel="noopener noreferrer">Детальніше про категорію ТЗ на сайті EUA</a></CustomTooltip>
                                            </div>
                                        }
                                    />
                                    <input type="text" name="kind" placeholder="Тип - CAR, MOTO..." onChange={handleKindChange} ref={kindInput} value={kind} />
                                    <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про тип ТЗ" 
                                        spoilerContent={
                                            <div className="vehicle-kind-details">
                                                <p className="font-bold">Тип автомобіля</p>
                                                <p>Допустимі значення:</p>
                                                <ul>
                                                    <li><span className="font-bold">MOTO</span> - мотоцикл</li>
                                                    <li><span className="font-bold">CAR</span> - автомобіль</li>
                                                    <li><span className="font-bold">PASSENGER</span> - автобус</li>
                                                    <li><span className="font-bold">FREIGHT</span> - вантажний автомобіль</li>
                                                    <li><span className="font-bold">TRAILER</span> - причіп</li>
                                                    <li><span className="font-bold">AGRICULTURAL</span> - сільськогосподарська техніка</li>
                                                    <li><span className="font-bold">SPECIAL</span> - спецтранспорт</li>
                                                </ul>
                                                <hr/>
                                                <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoModel" target="_blank" rel="noopener noreferrer">Детальніше про тип ТЗ на сайті EUA</a></CustomTooltip>
                                            </div>
                                        }
                                    />
                                    <input type="text" name="brand" placeholder="Бренд - Skoda" onChange={handleBrandChange} ref={brandInput} />
                                    <input type="text" name="model" placeholder="Модель - Octavia A7" onChange={handleModelChange} ref={modelInput} />
                                    <input type="text" name="modelText" placeholder="Назва - Skoda Octavia A7" ref={modelTextInput} value={`${vehicle.brand} ${vehicle.model}`} readOnly={true} />
                                    <input type="text" name="stateNumber" placeholder="Державний номер - AА0000AA" onChange={handleStateNumberChange} ref={stateNumberInput} value={stateNumber} />
                                    <div className="form-group form-check">
                                        <input name="dontHaveVIN" id="dont-have-vin" type="checkbox" hidden checked={isVINChecked} onChange={handleDontHaveVINChange} disabled />
                                        <label htmlFor="dont-have-vin">Немає VIN коду</label>
                                    </div>
                                    <input type="text" name="autoColor" placeholder="Колір - БІЛИЙ" onChange={handleAutoColorChange} ref={autoColorInput} value={vehicleColor} />
                                    <div className="switch-container">
                                        <label>
                                            <Switch onChange={handleForeignStateChange} checked={isForeign} onColor="#1BA876" />
                                            <div className="switch-text">Iноземна реєстрація</div>
                                        </label>
                                    </div>
                                    {!isForeign &&
                                     <>
                                        <AutoComplete ref={vehicleRegLocationInput} content={city} handleContent={handleVechileRegPlace} handleContentSuggestion={handleRegPlaceSuggestion} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} />
                                        <div className="vehicle-reg-location">
                                            <RegCities handleVechicleRegCity={setVechicleRegCity} />
                                        </div>
                                        <input type="number" name="regPlaceID" placeholder="Ідентифікатор місця реєстрації" ref={regPlaceIDInput} readOnly={true} value={regPlaceID} />
                                        <input type="text" name="regPlaceCode" placeholder="Код місця реєстрації" ref={regPlaceCodeInput} readOnly={true} value={regPlaceCode} />
                                     </>
                                    }
                                </div>
                                <button type="submit" className="submit-btn" onClick={addVehicle}><i className="fa fa-plus notification-icon"></i>Додати</button>
                                <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                            </>
                            :
                            <>
                                {!hideSuccessNotification && vehicleSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleSuccessMsg}</div>}
                            </>
                            }
                            <button type="button" className="admin-btn" onClick={navToVehiclesPage}>Транспортні засоби</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                        </div>
                        {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                      </>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddVehiclePage;