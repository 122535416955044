import React, {useState} from "react";
import useForm from "react-hook-form";
import CustomTooltip from "../Forms/common/CustomTooltip";
import CustomField from "../MainPage/CustomField";

const PartnerLoginComponent = ({handleSubmit, handleClose, errorMsg, handleCloseNotifMsg, emailPattern, handleEmail, handlePassword}) => {
    const [showChars, setCharsShown] = useState(false);

    const {register, errors} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange",
        nativeValidation: false
    });

    const handleCharsVisibility = () => {
      setCharsShown(!showChars);
    };

    return (
        <div className="dialog-wrapper">
            <CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark dialog-close-icon" onClick={() => handleClose()}></i></CustomTooltip>
              <div className="sign-in-wrapper">
                <div className="sign-in-container">
                  <CustomTooltip msg="Ваш email">
                    <CustomField
                        register={register({
                            required: "Це поле обов'язкове",
                            pattern: {
                              value: emailPattern,
                              message: "Введіть вірний email"
                            }
                        })}
                        errors={errors}
                        name="email"
                        label="Email"
                        defaultValue=""
                        readOnly={false}
                        placeholder="partner@pess.org.ua"
                        onChange={(event) => handleEmail(event.target.value)}
                    />
                  </CustomTooltip>
                </div>
                <div className="sign-in-container">
                <CustomTooltip msg="Ваш пароль">
                  <CustomField
                      register={register({
                          required: "Це поле обов'язкове"
                      })}
                      errors={errors}
                      name="password"
                      label="Пароль"
                      type={showChars ? "text" : "password"}
                      defaultValue=""
                      readOnly={false}
                      placeholder="e16525ee-2fb9-5baa-89d9-7967-d8c4525c"
                      onChange={(event) => handlePassword(event.target.value)}
                  />
                </CustomTooltip>
                <CustomTooltip msg={showChars ? "Приховати" : "Показати"}><i className={`fa-solid ${showChars ? "fa-eye-slash" : "fa-eye"} password-visibility-icon`} onClick={handleCharsVisibility}></i></CustomTooltip>
                </div>
              </div>
              {errorMsg && <div className="login-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={() => handleCloseNotifMsg()}></i></CustomTooltip>{errorMsg}</div>}
              <div className="dialog-controls">
                <button type="submit" className="dialog-btn" onClick={() => handleSubmit()}><i className="fa fa-lock notification-icon"></i>Увійти</button>
                <button type="button" className="dialog-btn" onClick={() => handleClose()}><i className="fa-solid fa-xmark notification-icon"></i>Закрити</button>
              </div>
          </div>
    );
};

export default PartnerLoginComponent;