import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import {navToGeneralSettings, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const AddGeneralSettingPage = (props) => {
    const dashboardHistory = useHistory();
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [settingItemError, setSettingItemError] = useState("");
    const [settingState, setSettingState] = useState(false);
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const [authInProgress, setAuthInProgress] = useState(false);
    const [generalSetting, setGeneralSetting] = useState({
        name: "",
        isActive: false,
        itemID: 0
    });

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchGeneralSettingItemID = async () => {
            try {
                const res = await dbAxiosInstance.get("/general-setting-item-id");
                const dbGeneralSettingData = res.data;

                if (dbGeneralSettingData) {
                    if (dbGeneralSettingData.length > 0) {
                        setGeneralSetting((prev) => ({...prev, itemID: dbGeneralSettingData[0].item_id + 1}));
                    } else {
                        setGeneralSetting({name: "", isActive: false, itemID: 0});
                    }

                    setSettingItemError("");
                } else {
                    setGeneralSetting({name: "", isActive: false, itemID: 0});
                    setSettingItemError("Помилка БД! Не вдалося завантажити ID елемента зашальних налаштувань.");
                }
            } catch (error: any) {
                setGeneralSetting({name: "", isActive: false, itemID: 0});
                setSettingItemError("Помилка БД! Не вдалося завантажити ID елемента зашальних налаштувань.");
            }
        };

        fetchGeneralSettingItemID();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleItemSubmit = async (event) => {
        event.preventDefault();
        
        if (generalSetting.name.trim().length > 0) {
            try {
                await dbAxiosInstance.post("/add-general-setting", generalSetting);
                setSettingItemError("");
                setErrorNotificationHidden(true);
                dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/general-settings`});
            } catch (error: any) {
                const userDataError = error.toJSON();
                setSettingItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            } 
        } else {
            setSettingItemError("Будь ласка, заповніть усі поля, щоб додати загальні налаштування!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        setErrorNotificationHidden(true);
        setGeneralSetting({name: "", isActive: false, itemID: 0});
        setSettingState(false);
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const handleNameChange = (event) => {
        setGeneralSetting((prev) => ({...prev, name: event.target.value}));
    };

    const handleSettingStateChange = (state) => {
        setSettingState(state);
        setGeneralSetting((prev) => ({...prev, isActive: state}));
    };

    const handleElementIDChange = (event) => {
        setGeneralSetting((prev) => ({...prev, itemID: event.target.value}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                        <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                       <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1>Додати елемент загальних налаштувань</h1>
                            <div className="add-dashboard-item-form">
                                <input type="text" name="name" placeholder="Назва" onChange={handleNameChange} />
                                <div className="switch-container">
                                    <label>
                                        <Switch onChange={handleSettingStateChange} checked={settingState} onColor="#1BA876" />
                                        <div className="switch-text">Включити налаштування</div>
                                    </label>
                                </div>
                                <input type="number" name="itemID" placeholder="ID елемента" onChange={handleElementIDChange} value={generalSetting.itemID} readOnly={true} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={handleItemSubmit}><i className="fa fa-plus notification-icon"></i>Додати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                            <button type="button" className="admin-btn" onClick={() => navToGeneralSettings(dashboardHistory, id, externalID)}>Загальні налаштування</button>
                        </div>
                        {!hideErrorNotification && settingItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{settingItemError}</div>}
                      </>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddGeneralSettingPage;