import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {checkAuthToken, countRecords, filterRecords, formatPhoneNumber, getInsuranceTypeName, isMatchCaseSearch, validatePhoneNumber} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {insuranceTypes} from "../../../mock-data/insuranceTypes";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import FilterPagination from "../../../utils/FilterPagination";
import PolicyStatus from "../../../components/Forms/common/PolicyStatus";
import webConfig from "../../../config";
import DataFilter from "../../../utils/DataFilter";

const ReportsGreencardPage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [reports, setReports] = useState<any[]>([]);
    const [authInProgress, setAuthInProgress] = useState(false);
    const [reportsCount, setReportsCount] = useState(0);
    const [reportsErrorMsg, setReportsErrorMsg] = useState("");
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const greencardType = insuranceTypes[1].value;
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const res = await dbAxiosInstance.get(`/reports/${encodeURIComponent(greencardType)}`);
                const dbReportsData = res.data;

                if (dbReportsData) {
                    if (dbReportsData.length > 0) {
                        setReports(dbReportsData);
                        setFilteredItems(dbReportsData);
                        setReportsErrorMsg("");
                    } else {
                        setReports([]);
                        setFilteredItems([]);
                        setReportsErrorMsg("Немає доданих даних звітів. Застрахуйте ТЗ, щоб додати звіт.");
                    }
                } else {
                    setReports([]);
                    setFilteredItems([]);
                    setReportsErrorMsg("Помилка БД! Не вдалося завантажити дані звітів.");
                }
            } catch (error: any) {
                setReports([]);
                setFilteredItems([]);
                setReportsErrorMsg("Помилка БД! Не вдалося завантажити дані звітів.");
            }
        };

        const countReports = async () => {
            try {
                const res = await dbAxiosInstance.get(`/count-reports/${encodeURIComponent(greencardType)}`);
                const dbCountReportsData = res.data;

                if (dbCountReportsData) {
                    if (dbCountReportsData.length > 0) {
                        setReportsCount(dbCountReportsData[0].counter);
                    } else {
                        setReportsCount(0);
                    }
                } else {
                    setReportsCount(0);
                }
            } catch (error: any) {
                setReportsCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchReports();
        countReports();
        checkSearchType();
    }, []);

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/report/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(reportsCount);
            setReportsCount(allRecords);

            if (allRecords === 0) {
                setReportsErrorMsg("Немає доданих даних звітів. Застрахуйте ТЗ, щоб додати звіт.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setReportsErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setReports([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp; 
                            <CustomTooltip msg="Перейти до Звітів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/reports`}}>Звіти</Link></CustomTooltip> &laquo; Зелена карта</h1>
                        <div className="itemsCounter">
                            <p>Звітів ({getInsuranceTypeName(greencardType)}): <span className="font-bold">{reportsCount}</span></p>
                        </div>
                        <DataFilter items={reports} matchCase={isMatchCaseFilter} placeholderText="Введіть дані звіту для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такого звіту не знайдено!" />
                        <div className="dashboard">
                            {reports.length > 0 && reportsCount > 0 ?
                                <div className="reports-wrapper">
                                    <table className="reports">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Телефон</th>
                                            <th>Страховик</th>
                                            <th>Франшиза</th>
                                            <th>Статус</th>
                                            <th>Сума</th>
                                            <th>Управління</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recordsOnPage.map((report, index) =>
                                            <tr key={index}>
                                                <td>{report.id}</td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі звіту"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/view`}}>{formatPhoneNumber(report.customer_phone_number)}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі звіту"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/view`}}>{report.insurer_name}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі звіту"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/view`}}>{report.franchise}</Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі звіту"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/view`}}><PolicyStatus status={report.policy_status} /></Link></CustomTooltip></td>
                                                <td><CustomTooltip msg="Натисність, щоб переглянути деталі звіту"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/view`}}>{`${report.total_amount.toFixed(2)} грн`}</Link></CustomTooltip></td>
                                                <td>
                                                   <div className="manage-item-container">
                                                        <CustomTooltip msg="Редагувати">
                                                            <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}/${report.id}/edit`}}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg="Переглянути деталі звіту">
                                                            <Link className="view-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/report/${greencardType}/${validatePhoneNumber(report.customer_phone_number)}/${report.vehicle_state_number.toLowerCase()}//${report.id}/view`}}>
                                                                <i className="fa fa-circle-info"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(report.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    </table>
                                    <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={(state) => setFilterCompleted(state)} isFilterProcessing={processingFilter} setFilterState={(state) => setProcessingFilter(state)} />
                                </div>
                              : <div>
                                    <div className="no-dashboard-item">{reportsErrorMsg}</div>
                                </div>
                            }
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ReportsGreencardPage;