import React from "react";
import Steps from "../components/MainPage/Steps";
import {hasInsurance} from "../utils/utilsGeneral";
import {Route} from "react-router-dom";
import StepsProvider from "../utils/StepsProvider";
import NotFoundComponent from "./common/NotFoundPage";

const InsurancePage = (props: any) => {
  const name = props.match.params.name;
  const instance = hasInsurance(name);

  if (instance) {
    return (
      <StepsProvider>
          <Route component={Steps} />
      </StepsProvider>
    )
  }
  else {
    return (
      <NotFoundComponent />
    );
  }
};

export default InsurancePage;