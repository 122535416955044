import React from "react";

const TabContent = ({tabs, currentTab}) => {
    return (
      tabs.map((tab) => (
        <div className={`tab-panel ${currentTab === `tab-${tab.index}` ? "active" : ""}`}>
            {tab.children}
        </div>
      ))
    );
};

export default TabContent;