import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {TabsProps} from "./tab";
import TabList from "./TabList";
import TabContent from "./TabContent";
import {navToHomePage} from "./utilsGeneral";

const TabsComponent = ({tabs, defaultTab = 1, orientation = "horizontal", tabsDisabledMsg}: TabsProps) => {
  const userHistory = useHistory();
  const [selectedTab, setSelectedTab] = useState(`tab-${defaultTab}`);
  const [tabsDisabled, setTabsDisabled] = useState(false);
  
  useEffect(() => {
    const initialTab = tabs.find((tab) => !tab.isDisabled);

    if (initialTab) {
        setSelectedTab(`tab-${initialTab.index}`);
        setTabsDisabled(false);
    } else {
        setTabsDisabled(true);
    }
  }, [tabs]);

  return (
    <div className="tabs-wrapper">
      <div className="tabs-container">
        <div className={`tab-list ${orientation}`}>
          <TabList tabs={tabs} currentTab={selectedTab} orientation={orientation} tabSelected={(tabID) => setSelectedTab(tabID)} />
        </div>
        <div className="tab-panels">
          {!tabsDisabled ? 
            <TabContent tabs={tabs} currentTab={selectedTab} /> 
           : 
            <div>
              <div className="customer-error-notification">{tabsDisabledMsg}</div>
              <button type="button" className="insurance-btn" onClick={() => navToHomePage(userHistory)}>На головну</button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;