import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {refreshPage, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import CustomTextarea from "../../../components/MainPage/CustomTextarea";

const ViewPromocodePage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [promocodeSuccessMsg, setPromocodeSuccessMsg] = useState("");
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const {id, externalID, promocode, promocodeID} = useParams<{
        id: string;
        externalID: string;
        promocode: string;
        promocodeID: string;
    }>();
    const [promocodesData, setPromocodesData] = useState<any[]>([]);
    const [authInProgress, setAuthInProgress] = useState(false);
    const [isCopyBtnClicked, setCopyBtnClicked] = useState(false);
    const [isClearBtnClicked, setClearBtnClicked] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchPromocode = async () => {
            try {
                const res = await dbAxiosInstance.get(`/promocode/${encodeURIComponent(promocode.toUpperCase())}/${encodeURIComponent(promocodeID)}`);
                const dbPromocodeData = res.data;

                if (dbPromocodeData) {
                    if (dbPromocodeData.length > 0) {
                        setPromocodesData(getPromocodes(dbPromocodeData));
                        setPromocodeErrorMsg("");
                    } else {
                        setPromocodesData([]);
                        setPromocodeErrorMsg("Немає такого промокоду. Натисніть на кнопку \"Додати\", щоб додати промокод.");
                    }
                } else {
                    setPromocodesData([]);
                    setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
                }
            } catch (error: any) {
                setPromocodesData([]);
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
            }
        };

        fetchPromocode();
    }, []);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
            setSuccessNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const getPromocodes = (promocodes) => { 
        const allPromocodes = promocodes.map((data) => data.promocode);
        return allPromocodes;
    };

    const copyPromocodes = () => {
        setPromocodeSuccessMsg("Промокод(и) успішно скопійовано до буферу обміну.");
        setSuccessNotificationHidden(false);
        setCopyBtnClicked(false);
    };

    const navToPromocodesPage = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
    };

    const fetchAllPromocodes = async (event) => {
        try {
            const res = await dbAxiosInstance.get("/promocodes");
            const dbPromocodesData = res.data;

            if (dbPromocodesData) {
                if (dbPromocodesData.length > 0) {
                    setPromocodesData(getPromocodes(dbPromocodesData));
                    setPromocodeErrorMsg("");
                } else {
                    setPromocodesData([]);
                    setPromocodeErrorMsg("Немає доданих промокодів. Натисніть на кнопку \"Додати\", щоб додати промокод.");
                }
            } else {
                setPromocodesData([]);
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокоди.");
            }
        } catch (error: any) {
            setPromocodesData([]);
            setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокоди.");
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const addPromocodes = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/add-promocode`});
    };

    const clearField = () => {
        setPromocodesData([]);
        setClearBtnClicked(false);
    };

    const promocodeProperties = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/view-promocode/${promocode}/${promocodeID}/properties`});
    };

    const editPromocode = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/edit-promocode/${promocode}/${promocodeID}`});
    };

    const deletePromocode = async (id) => {
        try {
            await dbAxiosInstance.delete(`/promocode/${encodeURIComponent(id)}`);
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
        } catch (error: any) {
            const userDataError = error.toJSON();
            setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setPromocodesData([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Промокод &#8212; {promocode.toUpperCase()}</h1>
                        {!hideSuccessNotification && promocodeSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeSuccessMsg}</div>}
                        <CustomTextarea
                            name="promocodes"
                            label="Промокоди"
                            placeholder="Всі промокоди" 
                            value={promocodesData}
                            isBBCode={false}
                            isFocus={false}
                            rows={8}
                            readOnly={true}
                            isCopy={isCopyBtnClicked}
                            isClear={isClearBtnClicked}
                            setDataCopiedToClipboard={copyPromocodes}
                            setDataCleared={clearField}
                        />
                        <button type="button" className="admin-btn" onClick={addPromocodes}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="submit" className="admin-btn" onClick={editPromocode}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => deletePromocode(promocodeID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={() => setCopyBtnClicked(true)}><i className="fa-regular fa-copy notification-icon"></i>Копіювати</button>
                        <button type="button" className="admin-btn" onClick={promocodeProperties}><i className="fa-regular fa-circle-info notification-icon"></i>Властивості</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={fetchAllPromocodes}><i className="fa-sharp fa-solid fa-eye notification-icon"></i>Показати всі</button>
                        <button type="button" className="admin-btn" onClick={() => setClearBtnClicked(true)}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewPromocodePage;