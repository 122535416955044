import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {checkAuthToken} from "../../../utils/utilsGeneral";

const PolicyExpiration = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID} = useParams<{
        id: string; 
        externalID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <div className="dashboard-container">
                            <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Термін дії поліса</h1>
                            <div className="dashboard">
                                <ul className="dashboard-items">
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="Автоцивілка"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/policy-expiration/epolicy`}}>Автоцивілка</Link></CustomTooltip>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="Зелена карта"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/policy-expiration/greencard`}}>Зелена карта</Link></CustomTooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default PolicyExpiration;