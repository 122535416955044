import { RECEIVED } from './someTypes';

function receiveProperties(type: string, json: any) {
  return {
    type: type,
    someData: json
  };
}

function fetchSomeData() {
  const data = {
      id: 1,
      name: 'data 1'
  };
  return (dispatch: any) => {
    setTimeout(() => {
        dispatch(receiveProperties(RECEIVED, data));
    }, 500);
  };
}

export function fetchSomeDataIfNeeded() {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const someData = state.someData;
    
    if (!someData.items) {
      return dispatch(fetchSomeData());
    }
  };
}
