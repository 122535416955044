import React, {useEffect} from "react";
import {Router, useLocation} from "react-router-dom";
import {renderRoutes} from "react-router-config";

import HeaderComponent from "./components/common/HeaderComponent";
import FooterComponent from "./components/common/FooterComponent";
import {createBrowserHistory, createMemoryHistory} from "history";
import routes from "./routes";
import MediaQueryProvider from "./utils/MediaQueryProvider";

const isBrowser = typeof window !== "undefined";

let history: any = {};
history = !isBrowser ? createMemoryHistory() : createBrowserHistory();

const App: React.FC = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/"
      ||!window
      ||(window && !window.document)
    ) return;
    const el = window && window.document.querySelector(".section-main")!;

    if(el) {
      window.scroll({
        behavior: "smooth",
        top: el.getBoundingClientRect().height + 90
      })
    }
  }, [location.pathname]);

  return (
    <Router history={history}>
      <MediaQueryProvider>
        <div className="App">
          <HeaderComponent {...props} />
          {renderRoutes(routes)}
          <FooterComponent/>
        </div>
      </MediaQueryProvider>
    </Router>
  );
};

export default App;
