import React, {useState, useRef, useEffect} from "react";
import useForm from "react-hook-form";
import {useHistory, useParams} from "react-router-dom";
import promocodeGenerator from "voucher-code-generator";
import {getMaxDate, getCurrentDateTime} from "../../../utils/utilsDate";
import CustomField from "../../../components/MainPage/CustomField";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {checkValue, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import CustomTextarea from "../../../components/MainPage/CustomTextarea";
import {Promocode} from "../../../typings/IDataOffers";

const AddPromocodePage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const [promocodesData, setPromocodesData] = useState<Promocode>({
        promocodes: [],
        maxActivations: "10000",
        validUntil: "",
        added: ""
    });
    const [promocodesCount, setPromocodesCount] = useState(0);
    const promocodeCountInput = useRef<HTMLInputElement>(null);
    const promocodeMaxActivationInput = useRef<HTMLInputElement>(null);
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [isPromocodeAdded, setPromocodeAdded] = useState(false);
    const [promocodeSuccessMsg, setPromocodeSuccessMsg] = useState("");
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [isCopyBtnClicked, setCopyBtnClicked] = useState(false);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);

    const {register, errors, clearError} = useForm({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
            setSuccessNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const handleCountChange = (event) => {
        setPromocodesCount(event.target.value);
    };

    const generatePromocodes = (event) => {
        event.preventDefault();

        if (promocodesCount > 0 && promocodesData.maxActivations.length > 0 && promocodesData.validUntil) {
            const allPromocodes = promocodeGenerator.generate({
                length: 6,
                count: promocodesCount,
                charset: "0123456789",
                prefix: "PESS"
            });
            setPromocodesData((prev) => ({...prev, promocodes: allPromocodes}));
            addPromocodes(allPromocodes);
        } else {
            setPromocodeErrorMsg("Будь ласка, заповніть усі поля, щоб додати промокод!");
            setPromocodeSuccessMsg("");
            setErrorNotificationHidden(false);
            setSuccessNotificationHidden(true);
        }
    };

    const addPromocodes = async (promocodes) => {
        const maxActivations = promocodesData.maxActivations;
        const validUntil = promocodesData.validUntil;
        const added = promocodesData.added;

        promocodes.map(async (promocode) => {
            try {
                await dbAxiosInstance.post("/add-promocodes", {promocode, maxActivations, validUntil, added});
                setPromocodeErrorMsg("");
                setErrorNotificationHidden(true);
                setPromocodeAdded(true);
                setPromocodeSuccessMsg("Промокод(и) успішно додано. Натисність на кнопку \"Копіювати\", щоб зберегти промокод(и) в буфер обміну.");
                setSuccessNotificationHidden(false);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setPromocodeSuccessMsg("");
                setErrorNotificationHidden(false);
                setSuccessNotificationHidden(true);
            }
        });
    };

    const handlePromocodesChange = (data) => {
        setPromocodesData((prev) => ({...prev, promocodes: checkValue(data)}));
    };

    const handleMaxActivationCountChange = (event) => {
        setPromocodesData((prev) => ({...prev, maxActivations: checkValue(event.target.value)}));
    };

    const handlePromocodeValidUntil = (name, date) => {
        const promocodeAdded = getCurrentDateTime();
        setPromocodesData((prev) => ({...prev, validUntil: date, added: promocodeAdded}));
        clearError(name);
    };

    const clearFields = (event) => {
        event.preventDefault();
        setPromocodesData((prev) => ({...prev, promocodes: [], maxActivations: "", validUntil: "", added: ""}));
        setPromocodeErrorMsg("");
        setPromocodeSuccessMsg("");
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);

        if (promocodeCountInput.current) {
            promocodeCountInput.current.value = "";
        }

        if (promocodeMaxActivationInput.current) {
            promocodeMaxActivationInput.current.value = "";
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const copyPromocodes = () => {
        setPromocodeSuccessMsg("Промокод(и) успішно скопійовано до буферу обміну.");
        setSuccessNotificationHidden(false);
        setCopyBtnClicked(false);
    };

    const navToPromocodesPage = () => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        {!isPromocodeAdded ?
                          <>
                            <h1>Додати промокоди</h1>
                            <div className="add-dashboard-item-form">
                                <input type="number" name="count" placeholder="Кількість промокодів" onChange={handleCountChange} ref={promocodeCountInput} />
                                <input type="number" name="maxActivations" placeholder="Максимальна кількість активацій" onChange={handleMaxActivationCountChange} ref={promocodeMaxActivationInput} value={promocodesData.maxActivations} />
                                <CustomField register={register({required: "Це поле обов\'язкове"})} errors={errors} name="validUntil" label="Дійсний до" type="date" placeholder="РРРР.ММ.ДД" max={getMaxDate()} autoComplete="off" 
                                    onDateChange={handlePromocodeValidUntil} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={generatePromocodes}><i className="fa fa-plus notification-icon"></i>Додати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                          </>
                          :
                          <>
                            {!hideSuccessNotification && promocodeSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeSuccessMsg}</div>}
                            <CustomTextarea
                                name="promocodes"
                                label="Промокоди"
                                placeholder="Всі промокоди" 
                                value={promocodesData.promocodes} 
                                setDataChanged={handlePromocodesChange}
                                isBBCode={false}
                                isFocus={false}
                                rows={6}
                                isCopy={isCopyBtnClicked}
                                copiedToClipboard={copyPromocodes}
                            />
                            <button type="button" className="admin-btn" onClick={() => setCopyBtnClicked(true)}><i className="fa-regular fa-copy notification-icon"></i>Копіювати</button>
                            <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                          </>
                        }
                    </div>
                    {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default AddPromocodePage;