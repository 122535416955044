import React from "react";
import Countdown from "react-countdown";

const DiiaTimerComponent = ({diiaClassName, expiryTimestamp, onDiiaTimer}) => {
    return (
        <Countdown key={expiryTimestamp} date={expiryTimestamp} autoStart={true} 
            renderer={({minutes, seconds}) => (
                <div className={diiaClassName}>{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</div>
            )}
            onComplete={onDiiaTimer}
        />
    );
};

export default DiiaTimerComponent;