export const insuranceTypes = [
  {value: "epolicy", label: "Автоцивілка"},
  {value: "greencard", label: "Зелена карта"}
];

export const euaDocuments = [
    {value: "PASSPORT", label: "Паспорт", disabled: false},
    {value: "ID_PASSPORT", label: "ID карта", disabled: false},
    {value: "DRIVING_LICENSE", label: "Посвідчення водія", disabled: false},
    {value: "EXTERNAL_PASSPORT", label: "Закордонний паспорт", disabled: false}
];

export const diiaDocuments = [
    {value: "internal-passport", label: "ID карта", disabled: false},
    {value: "driver-license", label: "Посвідчення водія", disabled: false},
    {value: "foreign-passport", label: "Закордонний паспорт", disabled: false},
    {value: "vehicle-license", label: "Техпаспорт", disabled: false}
];

export const privilegeTypes = [
    {value: "PENSION_CERTIFICATE", label: "Пенсійне посвідчення", description: "PENSIONER", disabled: false},
    {value: "E_PENSION_CERTIFICATE", label: "Електронне пенсійне посвідчення", description: "PENSIONER", disabled: false},
    {value: "DISABILITY_CERTIFICATE", label: "Посвідчення про інвалідність", description: "DISABLED", disabled: false},
    {value: "VETERAN_CERTIFICATE", label: "Посвідчення учасника війни", description: "PENSIONER", disabled: false},
    {value: "CHERNOBYLETS", label: "Чорнобильське посвідчення", description: "CHERNOBYLETS", disabled: false}
];

export const allPolicyStatus = [
    {value: "DRAFT", label: "Чернетка", disabled: false},
    {value: "REQUEST", label: "Заявлений", disabled: false},
    {value: "SENDING_OTP", label: "Відправлення коду", disabled: false},
    {value: "SENDED_OTP", label: "Код відправлено", disabled: false},
    {value: "PAY_OFFER", label: "Оплата поліса", disabled: false},
    {value: "PAID_OFFER", label: "Поліс оплачено", disabled: false},
    {value: "EMITTED", label: "Укладений", disabled: false},
];