import React, {useEffect, useState} from "react";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {useHistory, useParams, Link} from "react-router-dom";
import {
    getInsuranceType, 
    getPrivilegeType, 
    navToAdminPanel, 
    refreshPage, 
    navToCustomersPage, 
    navToCustomersEpolicyPage, 
    navToCustomersGreencardPage,
    checkValue,
    validateINNCode,
    convertINNToBirthDate,
    checkAuthToken
} from "../../../utils/utilsGeneral";
import CustomSelect from "../../../components/common/CustomSelect";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import {patternEmail} from "../../../utils/getInstanceError";
import {insuranceTypes, privilegeTypes, euaDocuments} from "../../../mock-data/insuranceTypes";
import {getMaxDate, getCurrentDateTime, validateDBDate} from "../../../utils/utilsDate";
import PassportPartForm from "../../../components/MainPage/PassportPartForm";
import IDCardPartForm from "../../../components/MainPage/IDCardPartForm";
import DriverLicensePartForm from "../../../components/MainPage/DriverLicensePartForm";
import PensionCertificateForm from "../../../components/MainPage/PrivilegeForms/PensionCertificate";
import EPensionCertificateForm from "../../../components/MainPage/PrivilegeForms/EPensionCertificate";
import DisabilityCertificateForm from "../../../components/MainPage/PrivilegeForms/DisabilityCertificate";
import VeteranCertificateForm from "../../../components/MainPage/PrivilegeForms/VeteranCertificate";
import ChernobylCertificateForm from "../../../components/MainPage/PrivilegeForms/ChernobylCertificate";
import {ICustomer} from "../../../redux-data/user/userType";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import webConfig from "../../../config";
import Switch from "react-switch";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import ExternalPassportPartForm from "../../../components/MainPage/ExternalPassportPartForm";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const AddCustomerPage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID, insuranceType} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
    }>();
    const [authInProgress, setAuthInProgress] = useState(false);
    const [currentInsuranceType, setCurrentInsuranceType] = useState(getInsuranceType(insuranceType) || "");
    const [isPrivilegeChecked, setPrivilegeChecked] = useState(false);
    const [customerDocument, setCustomerDocument] = useState("PASSPORT");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const [customerErrorMsg, setCustomerErrorMsg] = useState("");
    const [customerSuccessMsg, setCustomerSuccessMsg] = useState("");
    const [isCustomerAdded, setCustomerAdded] = useState(false);
    const [customer, setCustomer] = useState<ICustomer>({
        phoneNumber: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        innCode: "",
        dontHaveInnCode: false,
        isPrivilege: false,
        documentType: customerDocument,
        documentSeries: "",
        documentNumber: "",
        documentIssuedDate: null,
        documentExpirationDate: null,
        documentIssuedBy: "",
        addressSettlementName: "",
        addressStreet: "",
        addressHouseNumber: "",
        addressApartmentNumber: "",
        vehicleStateNumber: "",
        insuranceType: currentInsuranceType.value,
        lastModified: null
    });
    const [customerBirthDate, setCustomerBirthDate] = useState("");
    const [invalidInnMsg, setInvalidInnMsg] = useState("");
    const [isINNInputDisabled, setINNInputDisabled] = useState(false);

    const {register, errors, clearError} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange",
        nativeValidation: false
    });
    
    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const handleInsuranceTypesChange = (type) => {
        setCurrentInsuranceType(type);
        setCustomer((prev) => ({...prev, insuranceType: checkValue(type.value)}));
    };

    const handlePhoneNumberChange = (event) => {
        setCustomer((prev) => ({...prev, phoneNumber: checkValue(event.target.value)}));
    };

    const handleEmailChange = (event) => {
        setCustomer((prev) => ({...prev, email: checkValue(event.target.value)}));
    };

    const handleLastNameChange = (event) => {
        setCustomer((prev) => ({...prev, lastName: checkValue(event.target.value)}));
    };

    const handleNameChange = (event) => {
        setCustomer((prev) => ({...prev, firstName: checkValue(event.target.value)}));
    };

    const handleMiddleNameChange = (event) => {
        setCustomer((prev) => ({...prev, middleName: checkValue(event.target.value)}));
    };

    const handleBirthDateChange = (name, date) => {
        setCustomer((prev) => ({...prev, birthDate: validateDBDate(date)}));
        clearError(name);
    };

    const handleInnCodeChange = (event) => {
        const innCode = event.target.value;
    
        if (innCode.length === Number(webConfig.customerINNCodeLength)) {
            if (validateINNCode(innCode)) {
                const {year, month, day} = convertINNToBirthDate(innCode);
                const birthDateFromInn = `${year}-${month}-${day}`;
                setCustomerBirthDate(birthDateFromInn);
                setInvalidInnMsg("");
                setCustomer((prev) => ({...prev, innCode: checkValue(innCode), dontHaveInnCode: false, birthDate: validateDBDate(birthDateFromInn)}));
            } else {
                setInvalidInnMsg("Невірний ідентифікаційний номер");
            }
        } else {
            setInvalidInnMsg("");
        }
    };

    const handlePrivilegeChange = (event) => {
        const isPrivilegeChecked = event.target.checked;
        setPrivilegeChecked(isPrivilegeChecked);

        if (!isPrivilegeChecked) {
            setCustomerDocument("PASSPORT");
        }

        setCustomer((prev) => ({...prev, isPrivilege: isPrivilegeChecked}));
    };

    const handlePrivilegeTypeChange = (type) => {
        setCustomerDocument(type.value);
        setCustomer((prev) => ({...prev, documentType: checkValue(type.value)}));
    };

    const handleDocumentChange = (event) => {
        setCustomerDocument(event.target.value);
        setCustomer((prev) => ({...prev, documentType: checkValue(event.target.value), isPrivilege: false}));
    };

    const handleDocumentSeriesChange = (event) => {
        setCustomer((prev) => ({...prev, documentSeries: checkValue(event.target.value)}));
    };

    const handleDocumentNumberChange = (event) => {
        setCustomer((prev) => ({...prev, documentNumber: checkValue(event.target.value)}));
    };

    const handleDocumentIssuedByChange = (event) => {
        setCustomer((prev) => ({...prev, documentIssuedBy: checkValue(event.target.value)}));
    };

    const handleDocumentIssuedDateChange = (name, date) => {
        setCustomer((prev) => ({...prev, documentIssuedDate: validateDBDate(date)}));
        clearError(name);
    };

    const handleDocumentExpirationDateChange = (name, date) => {
        setCustomer((prev) => ({...prev, documentExpirationDate: validateDBDate(date)}));
        clearError(name);
    };

    const handleSettlementNameChange = (event) => {
        setCustomer((prev) => ({...prev, addressSettlementName: checkValue(event.target.value)}));
    };

    const handleAddressStreetChange = (event) => {
        setCustomer((prev) => ({...prev, addressStreet: checkValue(event.target.value)}));
    };

    const handleAddressHouseNumberChange = (event) => {
        setCustomer((prev) => ({...prev, addressHouseNumber: checkValue(event.target.value)}));
    };

    const handleAddressApartmentNumberChange = (event) => {
        setCustomer((prev) => ({...prev, addressApartmentNumber: checkValue(event.target.value)}));
    };

    const handleVehicleStateNumberChange = (event) => {
        setCustomer((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const handleCustomerSubmit = async (event) => {
        event.preventDefault();
        
        if (Object.keys(errors).length === 0 && customer.phoneNumber.length > 0 && customer.email.length > 0 && customer.firstName.length > 0 && customer.lastName.length > 0 && customer.birthDate && 
            ((currentInsuranceType.value === "epolicy" && customer.middleName.length > 0 && customer.innCode.length > 0 && validateINNCode(customer.innCode)) || 
            (currentInsuranceType.value === "greencard" && !isINNInputDisabled && customer.innCode.length > 0 && validateINNCode(customer.innCode)) || (currentInsuranceType.value === "greencard" && isINNInputDisabled)) && 
            customer.documentType.length > 0 && customer.documentNumber.length > 0 && customer.addressSettlementName.length > 0 && customer.addressStreet.length > 0 && customer.addressHouseNumber.toString().length > 0 && 
            customer.addressApartmentNumber.toString().length > 0 && customer.vehicleStateNumber.length > 0 && customer.insuranceType.length > 0 && customer.lastModified) {
            try {
                const res = await dbAxiosInstance.post("/add-customer", customer);
                setCustomerSuccessMsg(res.data);
                setCustomerAdded(true);
                setCustomerErrorMsg("");
                setSuccessNotificationHidden(false);
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setCustomerErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setCustomerAdded(false);
                setCustomerSuccessMsg("");
                setSuccessNotificationHidden(true);
                setErrorNotificationHidden(false);
            }
        } else {
            setCustomerErrorMsg("Будь ласка, заповніть усі поля, щоб додати дані клієнта!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        setCustomerErrorMsg("");
        setCustomerSuccessMsg("");
        setSuccessNotificationHidden(true);
        setErrorNotificationHidden(true);
        setCustomerAdded(false);
        setCustomer({phoneNumber: "", email: "", firstName: "", middleName: "", lastName: "", birthDate: "", innCode: "", dontHaveInnCode: false, isPrivilege: false, documentType: "", documentSeries: "", documentNumber: "", documentIssuedDate: null, 
                    documentExpirationDate: null, documentIssuedBy: "", addressSettlementName: "", addressStreet: "", addressHouseNumber: "", addressApartmentNumber: "", vehicleStateNumber: "", insuranceType: "", lastModified: null});
        setCurrentInsuranceType({label: "", value: ""});
        setPrivilegeChecked(false);         
        setCustomerDocument("PASSPORT");
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const handleInnStateChange = (isInnDisabled) => {
        setINNInputDisabled(isInnDisabled);
        setCustomer((prev) => ({...prev, innCode: isInnDisabled ? "" : customer.innCode, dontHaveInnCode: isInnDisabled}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                  <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                            <CustomTooltip msg="Перейти до Клієнтів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/customers`}}>Клієнти</Link></CustomTooltip> &laquo;&nbsp; Додати дані клієнта</h1>
                        {isCustomerAdded ?
                              <>
                                {!hideSuccessNotification && customerSuccessMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerSuccessMsg}</div>}
                                <div>
                                    <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardHistory, id, externalID)}>Клієнти</button>
                                    <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardHistory, id, externalID)}>Клієнти (Автоцивілка)</button>
                                    <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardHistory, id, externalID)}>Клієнти (Зелена карта)</button>
                                    <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                                </div>
                              </>
                            :
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                    <label>Тип страхування</label>
                                    <CustomSelect
                                        options={insuranceTypes}
                                        handleChange={handleInsuranceTypesChange}
                                        value={currentInsuranceType}
                                        isCreatable={false}
                                        isDisabled={false}
                                        placeholder="Тип страхування"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /[+]\d{12}/,
                                        message: "Введіть вірний телефон",
                                    },
                                    maxLength: {
                                        value: 13,
                                        message: "Введіть вірний телефон",
                                    }
                                })}
                                errors={errors}
                                name="phone_number"
                                label="Номер телефона"
                                defaultValue="+38"
                                readOnly={false}
                                onChange={handlePhoneNumberChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: patternEmail,
                                        message: "Введіть вірний email"
                                    }
                                })}
                                errors={errors}
                                name="email"
                                label="Email"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleEmailChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                        message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                    }
                                })}
                                errors={errors}
                                name="sername"
                                label="Прізвище"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleLastNameChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                        message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                    }
                                })}
                                errors={errors}
                                name="name"
                                label="Iм'я"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleNameChange}
                                />
                            </div>
                            {currentInsuranceType.value === "epolicy" &&
                                <div className="col-md-4">
                                    <CustomField
                                    register={register({
                                        required: "Це поле обов'язкове",
                                        pattern: {
                                            value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                            message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                        }
                                    })}
                                    errors={errors}
                                    name="middle_name"
                                    label="По батькові"
                                    defaultValue=""
                                    readOnly={false}
                                    onChange={handleMiddleNameChange}
                                    />
                                </div>
                            }
                            <div className="col-md-4">
                                {((currentInsuranceType.value === "epolicy") || (currentInsuranceType.value === "greencard" && !isINNInputDisabled)) &&
                                    <CustomField
                                    register={register({
                                        required: "Це поле обов'язкове",
                                        pattern: {
                                            value: /\d{10}/,
                                            message: "Ідентифікаційний номер містить 10 чисел"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Ідентифікаційний номер містить 10 чисел"
                                        }
                                    })}
                                    errors={errors}
                                    name="INN"
                                    label="Ідентифікаційний номер"
                                    defaultValue=""
                                    placeholder="1234567890"
                                    mask="9999999999"
                                    readOnly={false}
                                    onChange={handleInnCodeChange}
                                    />
                                }
                                {currentInsuranceType.value === "greencard" &&
                                    <div className="switch-container">
                                        <label>
                                            <Switch onChange={handleInnStateChange} checked={isINNInputDisabled} onColor="#1BA876" />
                                            <div className="switch-text">У мене немає ІПН</div>
                                        </label>
                                    </div>
                                }
                                {invalidInnMsg !== "" && <div className="item-error">{invalidInnMsg}</div>}
                            </div>
                            <div className="col-md-4">
                                <CustomField
                                register={register({
                                    required: "Це поле обов\'язкове"
                                })}
                                errors={errors}
                                name="date_of_birth"
                                label="Дата народження"
                                type="date"
                                placeholder="РРРР.ММ.ДД"
                                max={getMaxDate()}
                                autoComplete="off"
                                dateReadOnly={false}
                                dateValue={customerBirthDate}
                                onDateChange={handleBirthDateChange}
                                />
                            </div>
                            {currentInsuranceType.value === "epolicy" &&
                                <div className="col-md-4">
                                    <div className="privilege-checkbox">
                                        <div className="form-group form-check">
                                            <input
                                                ref={register}
                                                name="privilege"
                                                id="benefit"
                                                type="checkbox"
                                                hidden
                                                checked={isPrivilegeChecked}
                                                onChange={handlePrivilegeChange}
                                            />
                                            <label htmlFor="benefit">Є пільга</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {currentInsuranceType.value === "epolicy" && isPrivilegeChecked ?
                                <>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Тип пільги</label>
                                        <CustomSelect
                                            options={privilegeTypes}
                                            handleChange={handlePrivilegeTypeChange}
                                            value={getPrivilegeType(customerDocument)}
                                            isCreatable={false}
                                            isDisabled={false}
                                            placeholder="Тип пільги"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {privilegeTypes[0].value === customerDocument && <PensionCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                    {privilegeTypes[1].value === customerDocument && <EPensionCertificateForm errors={errors} register={register} numberClass="col-md-8" expirationDateClass="col-md-8" isReadOnly={false} 
                                                                                        documentNumberValue="" documentExpirationDateValue="" onDocumentNumberChange={handleDocumentNumberChange} 
                                                                                        onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                    }
                                    {privilegeTypes[2].value === customerDocument && <DisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                    {privilegeTypes[3].value === customerDocument && <VeteranCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                    {privilegeTypes[4].value === customerDocument && <ChernobylCertificateForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                    }
                                </div>
                                </>
                              :
                                <>
                                   <div className="col-md-10">
                                     <div className="document-type-wrapper">
                                        {euaDocuments.map((document) => (
                                            <div className="policy-document-type" key={document.value}>
                                                <div className="form-group form-radio">
                                                    <input
                                                        onChange={handleDocumentChange}
                                                        type="radio"
                                                        id={document.value}
                                                        value={document.value}
                                                        checked={document.value === customerDocument}
                                                        hidden
                                                        disabled={false}
                                                        name="document_type"
                                                        ref={register}
                                                    />
                                                    <label htmlFor={document.value}>{document.label}</label>
                                                </div>
                                            </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                        {euaDocuments[0].value === customerDocument && <PassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" isReadOnly={false} 
                                                                                        documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                        }
                                        {euaDocuments[1].value === customerDocument && <IDCardPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" expirationDateClass="col-md-8" 
                                                                                        issuedByClass="col-md-8" isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedByValue="" documentIssuedDateValue="" 
                                                                                        documentExpirationDateValue="" onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} 
                                                                                        onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} 
                                                                                        onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                                        }
                                        {euaDocuments[2].value === customerDocument && <DriverLicensePartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                                        }
                                        {euaDocuments[3].value === customerDocument && <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-8" numberClass="col-md-8" issuedDateClass="col-md-8" issuedByClass="col-md-8" 
                                                                                        isReadOnly={false} documentSeriesValue="" documentNumberValue="" documentIssuedDateValue="" documentIssuedByValue="" onDocumentSeriesChange={handleDocumentSeriesChange} 
                                                                                        onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />

                                        }
                                    </div>
                                </>
                            }
                            <div className="col-md-6">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                                        message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                    }
                                })}
                                errors={errors}
                                name="settlementName"
                                label="Назва населеного пункта"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleSettlementNameChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: currentInsuranceType.value === "epolicy" ? /^[а-яa-z-ієї.'\s]+$/i : /^[a-z.\s]+$/i,
                                        message: currentInsuranceType.value === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                                    }
                                })}
                                errors={errors}
                                name="address_street"
                                label="Вулиця"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleAddressStreetChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /\d/,
                                        message: "Тільки цифри"
                                    }
                                })}
                                errors={errors}
                                name="address_house_number"
                                label="Номер будинку"
                                placeholder="12"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleAddressHouseNumberChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /\d/,
                                        message: "Тільки цифри"
                                    }
                                })}
                                errors={errors}
                                name="address_apartment_number"
                                label="Номер квартири"
                                placeholder="12"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleAddressApartmentNumberChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <CustomField
                                register={register({
                                    required: "Це поле обов'язкове",
                                    pattern: {
                                        value: /[A-zА-я-І-і-Ї-ї0-9]/,
                                        message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                                    }
                                })}
                                errors={errors}
                                name="vehicle_state_number"
                                label="Державний номер ТЗ"
                                placeholder="AA1234BB"
                                defaultValue=""
                                readOnly={false}
                                onChange={handleVehicleStateNumberChange}
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="submit-btn" onClick={handleCustomerSubmit}><i className="fa fa-plus notification-icon"></i>Додати</button>
                                <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                                <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersPage(dashboardHistory, id, externalID)}>Клієнти</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersEpolicyPage(dashboardHistory, id, externalID)}>Клієнти (Автоцивілка)</button>
                                <button type="button" className="admin-btn" onClick={() => navToCustomersGreencardPage(dashboardHistory, id, externalID)}>Клієнти (Зелена карта)</button>
                                <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                            </div>
                            {!hideErrorNotification && customerErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{customerErrorMsg}</div>}
                        </div>
                        }
                    </div>
                  </>
                }
                </div>
            </div>
        </div>
    );
};

export default AddCustomerPage;