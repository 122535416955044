import {applyMiddleware, compose, createStore} from 'redux';
import reducers, {RootState} from './redux-data';
import thunkMiddleware from 'redux-thunk';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

export default function configureStore(ssrState: RootState | {} = {}) {
  const middlewares = [
    thunkMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];
  if (typeof window !== 'undefined') {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const store = createStore(reducers,
    ssrState,
    compose(...enhancers));

  (store as any).asyncReducers = {}; // Async reducer registry

  return store;
}
