import React, {useEffect, useState} from "react";
import {getPayloadLiqpay} from "../utils/liqpay";
import {useSelector} from "react-redux";
import {getSelectedItem, getSelectedAmountItem} from "../redux-data/insurance/insuranceSelector";
import {getOfferId, getOfferEmail} from "../redux-data/offer/offerReducer";
import {isSettingItemActive} from "../utils/utilsGeneral";
import webConfig from "../config";
import {liqPayAxiosInstance} from "../core/configLiqPayAxios";
import isWebConfigOn from "to-boolean";

interface LiqPayPageProps {
  onClose?: (data: any) => any;
  onReady?: (data: any) => any;
  onCallback?: (data: any) => any;
  type: "epolicy" | "greencard"
}
const LiqPayPage = ({type, onCallback, onClose, onReady}: LiqPayPageProps) => {
  const [canLoadScript, setCanLoadScript] = useState(false);
  const offerId = useSelector(getOfferId)!;
  const offerEmail = useSelector(getOfferEmail)!;
  const policy = useSelector(getSelectedItem);
  const policyAmount = useSelector(getSelectedAmountItem)!;
  const productPrice = policyAmount.amount > policyAmount.discountedAmount ? policyAmount.discountedAmount : policyAmount.amount;

  useEffect(() => {
    prepareLiqpay();
  }, []);

  useEffect(() => {
    if (!canLoadScript) {
        return;
    }
    if (!window) {
        return;
    }
    const checkout = window.document.createElement("script");
    checkout.src = "https://static.liqpay.ua/libjs/checkout.js";
    checkout.id = "checkout";
    window.document.body.appendChild(checkout);

    return () => {
      checkout.remove();
    };
  }, [canLoadScript]);

  const getDataForLiqpay = async (isSandbox) => {
    try {
      const res = await liqPayAxiosInstance.post("/api/v1/signature", {
        data: getPayloadLiqpay({
          amount: productPrice,
          order_id: offerId,
          isTest: isWebConfigOn(isSandbox),
          customerEmail: offerEmail,
          policyName: policy!.name,
          policyFranchise: policyAmount!.franchise,
          type
        }),
      });
      return {
        success: true,
        data: res.data
      };
    } catch (e) {
      console.error(">>> ERROR: ", e);
      return {
        success: false,
        data: null
      };
    }
  };

  const prepareLiqpay = async () => {
    const isSandboxMode = await isSettingItemActive(webConfig.enableLiqPaySandboxID);
    const res = await getDataForLiqpay(isSandboxMode);
    if (res.success) {
        const {data, signature} = res.data;
        window.LiqPayCheckoutCallback = function () {
          window.LiqPayCheckout.init({
            data,
            signature,
            embedTo: "#liqpay_checkout",
            language: "uk",
            mode: "embed", // embed || popup
          })
          .on("liqpay.callback", function (data) {
            onCallback && onCallback(data);
          })
          .on("liqpay.ready", function (data) {
            onReady && onReady(data);
          })
          .on("liqpay.close", function (data) {
            onClose && onClose(data);
          });
        };
        setCanLoadScript(true);
    }
  };

  return (
    <div className="pay">
      <div id="liqpay_checkout"></div>
    </div>
  );
};

export default LiqPayPage;
