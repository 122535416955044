import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Policy from "./Policy";
import DataOrder from "./DataOrder";
import {useStepsContext} from "../../utils/StepsProvider";
import {getOfferItems, getPolicyLoading, getSelectedItem} from "../../redux-data/insurance/insuranceSelector";
import {loadPolicies, setAmountId, setItemId, setPolicy} from "../../redux-data/insurance/insuranceReducer";
import PolicyAmount from "./PolicyAmount";
import {ICompanyInsurance} from "../../typings/IDataOffers";
import CustomLoader from "../Forms/common/CustomLoader";
import {getOfferError} from "../../redux-data/offer/offerReducer";
import {useHistory} from "react-router-dom";
import {handleInsuranceNav, isCompaniesSearchVisible, navToHomePage} from "../../utils/utilsGeneral";
import InsurancePolicyFilter from "../../utils/InsurancePolicyFilter";
import webConfig from "../../config";

const compareEpolicy = (a: ICompanyInsurance, b: ICompanyInsurance) => {
  const minA = Math.min(...a.data.map((d) => d.amount));
  const minB = Math.min(...b.data.map((d) => d.amount));
  return minA - minB;
};

const compareGreencard = (a, b) => {
  if (a.data[0].productCode === webConfig.guardianProductCode) {
      return -1;
  }

  if (b.data[0].productCode === webConfig.guardianProductCode) {
      return 1;
  }

  return a.name.localeCompare(b.name);
};

const StepTwo = ({type}: {type: "epolicy" | "greencard"}) => {
  const items = useSelector(getOfferItems);
  const isLoading = useSelector(getPolicyLoading);
  const mainItem = useSelector(getSelectedItem);
  const offerError = useSelector(getOfferError);
  const dispatch = useDispatch();
  const {goTo} = useStepsContext() as any;
  const userHistory = useHistory();
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [isSearchVisible, setSearchVisibility] = useState(false);

  useEffect(() => {
    const checkCompaniesSearch = async () => {
      const isOn = await isCompaniesSearchVisible();
      setSearchVisibility(isOn);
    };
    
    dispatch(loadPolicies(type));
    checkCompaniesSearch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mainItem]);

  useEffect(() => {
    if (items.length > 0) {
        setFilteredItems(items);
    }
  }, [items]);

  const handleChangeDataOrder = () => {
    goTo(1);
  };

  const handleBuy = (id: any) => {
    dispatch(setItemId(id));
    dispatch(setPolicy(items[id]));
  };

  const handleSelectPolicyAmount = (id: any) => {
    dispatch(setAmountId(id));
    goTo(3);
  };

  const handleBack = () => {
    setFilteredItems(items);
    dispatch(setItemId(null));
  };

  const NoItems = () => {
    return (
      <div className="col-sm-8">
        <h4>Нiчого не знайдено</h4>
        {offerError && 
          <div className="error-msg-visible"><p dangerouslySetInnerHTML={{__html: offerError}} />
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("epolicy", dispatch, userHistory)}>Автоцивілка</button>
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("greencard", dispatch, userHistory)}>Зелена карта</button>
            <button type="button" className="insurance-btn" onClick={() => navToHomePage(userHistory)}>На головну</button>
          </div>
        }
      </div>
    );
  };

  const allVisibleItems = items.filter((item) => !item.data[0].isHidden);

  return (
    <div className="row pay-choose">
      {isLoading ?
        <CustomLoader className="policy-loading" isSquare={false} isInfoOn={true} infoMsg="Триває підбір страхових компаній" isLoading={isLoading} />
       : allVisibleItems.length > 0 ?
        <>
          <div className="col-sm-6">
            <h4>
              Всього пропозицій <span className="green">{allVisibleItems.length}</span>
            </h4>
          </div>
          <div className="col-sm-2">
            {mainItem ?
              <button onClick={handleBack} className="btn btn-link btn-white-border active">
                Назад
              </button>
             : null}
          </div>
          <div className="col-lg-8">
            {!mainItem ?
              <>
                {isSearchVisible == true && <InsurancePolicyFilter data={items} onFilteredItems={(records) => setFilteredItems(records)} />}
                {filteredItems.length > 0 && type === "epolicy" && filteredItems.sort(compareEpolicy).map((item) => !item.data[0].isHidden && <Policy key={item.id} {...item} onBuy={handleBuy} />)}
                {filteredItems.length > 0 && type === "greencard" && filteredItems.sort(compareGreencard).map((item) => !item.data[0].isHidden && <Policy key={item.id} {...item} onBuy={handleBuy} />)}
              </>
             :
              mainItem.data
                .sort((a, b) => a.amount - b.amount)
                .map((item) => (
                  !item.isHidden &&
                  <PolicyAmount
                    key={item.id}
                    item={item}
                    name={mainItem.name}
                    companyId={mainItem.companyId}
                    onBuy={handleSelectPolicyAmount}
                  />
                ))
            }
          </div>
        </>
       : 
        <NoItems />
      }
      <div className={`col-lg-4 ${isLoading ? "offset-lg-8" : ""}`}>
        <div className="pay-choose_info">
          <DataOrder type={type} handleChangeDataOrder={handleChangeDataOrder} />
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
