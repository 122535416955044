import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {formatDBDate, formatDBDateTime} from "../../../utils/utilsDate";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {refreshPage, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const ViewPromocodePropertiesPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const dashboardHistory = useHistory();
    const {id, externalID, promocode, promocodeID} = useParams<{
        id: string;
        externalID: string;
        promocode: string;
        promocodeID: string;
    }>();
    const [promocodesData, setPromocodesData] = useState<any[]>([]);
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);
    
    useEffect(() => {
        const fetchPromocode = async () => {
            try {
                const res = await dbAxiosInstance.get(`/promocode/${encodeURIComponent(promocode.toUpperCase())}/${encodeURIComponent(promocodeID)}`);
                const dbPromocodeData = res.data;

                if (dbPromocodeData) {
                    if (dbPromocodeData.length > 0) {
                        setPromocodesData(dbPromocodeData);
                        setPromocodeErrorMsg("");
                    } else {
                        setPromocodesData([]);
                        setPromocodeErrorMsg("Немає такого промокоду. Натисніть на кнопку \"Додати\", щоб додати промокод.");
                    }
                } else {
                    setPromocodesData([]);
                    setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
                }
            } catch (error: any) {
                setPromocodesData([]);
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
            }
        };

        fetchPromocode();
    }, []);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification]);

    const navToPromocodesPage = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
    };

    const addPromocodes = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/add-promocode`});
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editPromocode = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/edit-promocode/${promocode}/${promocodeID}`});
    };

    const deletePromocode = async (id) => {
        try {
            await dbAxiosInstance.delete(`/promocode/${encodeURIComponent(id)}`);
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
        } catch (error: any) {
            const userDataError = error.toJSON();
            setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setPromocodesData([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Властивості промокоду &#8212; {promocode.toUpperCase()}</h1>
                        {promocodesData.length > 0 && promocodesData.map((promocode) => 
                            <div>
                                <p className="item-title">Кількість активацій: <span className="font-bold">{promocode.activations > 0 ? promocode.activations : 0}</span></p>
                                <p className="item-title">Максимальна кількість активацій: <span className="font-bold">{promocode.max_activations > 0 ? promocode.max_activations : 0}</span></p>
                                <p className="item-title">Дійсний до: <span className="font-bold">{formatDBDate(promocode.valid_until)}</span></p>
                                <p className="item-title">Є використаний: <span className="font-bold">{promocode.is_used === "true" ? <span className="used-promocode">Так</span> : <span className="new-promocode">Ні</span>}</span></p>
                                <p className="item-title">Додано/Оновлено: <span className="font-bold">{formatDBDateTime(promocode.added)}</span></p>
                            </div>
                        )}
                        <button type="button" className="admin-btn" onClick={addPromocodes}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="submit" className="admin-btn" onClick={editPromocode}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => deletePromocode(promocodeID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                        {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewPromocodePropertiesPage;