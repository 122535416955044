import React, {createContext, useContext} from "react";
import useReactRouter from "use-react-router";
import {v4} from "uuid";

const STEPS = 5;
const getGenerateSteps = (steps: number = STEPS) => {
  const res: any = {};
  const res2: any = {};
  for (let i = 1; i <= steps; i++) {
    const id = v4();
    res[i] = id;
    res2[id] = i;
  }
  return [res, res2];
};
const [stepToId, idToStep] = getGenerateSteps();

export const StepsContext = createContext(null);

export default function ({children}: any) {
  const {history, match} = useReactRouter();

  const getStepById = (id: any) => {
    return idToStep[id];
  };

  const getFirst = () => {
    return stepToId[1];
  };

  const goTo = (step: any) => {
    history.push("/" + match.params["name"] + "/" + stepToId[step]);
  };

  const value: any = {
    getStepById,
    goTo,
    getFirst,
  };

  return (<StepsContext.Provider value={value}>{children}</StepsContext.Provider>) as any;
}

export function useStepsContext() {
  return useContext(StepsContext);
}
