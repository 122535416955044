import Axios from "axios";
import React, {useEffect, useState} from "react";
import config from "../config";
import {useParams} from "react-router-dom";
import {getPayloadLiqpay} from "../utils/liqpay";

interface IPayPageProps {
  onClose?: (data: any) => any;
  onReady?: (data: any) => any;
  onCallback?: (data: any) => any;
  insuranceType: "epolicy" | "greencard"
}

const TestPayPage = ({insuranceType, onCallback, onClose, onReady}: IPayPageProps) => {
  const {id, type} = useParams<{
    id: string;
    type: string;
  }>();
  const [canLoadScript, setCanLoadScript] = useState(false);

  useEffect(() => {
    prepareLiqpay();
  }, []);

  useEffect(() => {
    if (!canLoadScript) {
        return;
    }
    if (!window) {
        return;
    }
    let checkout = window.document.createElement("script");
    checkout.src = "https://static.liqpay.ua/libjs/checkout.js";
    checkout.id = "checkout";
    window.document.body.appendChild(checkout);

    return () => {
      checkout.remove();
    };
  }, [canLoadScript]);

  const getDataForLiqpay = async () => {
    try {
      const res = await Axios.post("/api/v1/signature_test", {
        data: getPayloadLiqpay({
          amount: 11,
          order_id: id,
          isTest: true,
          customerEmail: "",
          policyName: "Тестовая страховая компания",
          policyFranchise: 3200,
          type: insuranceType
        }),
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (e) {
      console.error('>>> ERROR: ', e);
      return {
        success: false,
        data: null,
      };
    }
  };

  const prepareLiqpay = async () => {
    const res = await getDataForLiqpay();
    const {data, signature} = res.data;
    window.LiqPayCheckoutCallback = function () {
      window.LiqPayCheckout.init({
        data,
        signature,
        embedTo: '#liqpay_checkout',
        language: 'uk',
        mode: 'embed', // embed || popup
      })
        .on('liqpay.callback', async function (data) {
          console.log(data);

          try {
            const res = await Axios.post(config.euaBaseUrl + `LinqPay/result_liqpay/${type}`, data);
            console.log(res);
          } catch (e) {
            console.error('>>> ERROR: ', e);
          }

          onCallback && onCallback(data);
        })
        .on('liqpay.ready', function (data) {
          console.log(data);
          onReady && onReady(data);
        })
        .on('liqpay.close', function (data) {
          onClose && onClose(data);
        });
    };
    setCanLoadScript(true);
  };

  return (
    <div className="pay">
      <div
        id="liqpay_checkout"
        style={{
          marginTop: '50rem',
        }}/>
    </div>
  );
};

export default TestPayPage;
