import axios from "axios";
import CryptoJS from "crypto-js";
import webConfig from "../config";
import isWebConfigOn from "to-boolean";

const webToken = CryptoJS.AES.encrypt(webConfig.adminEmail, webConfig.passPhrase);
const pessOrigin = CryptoJS.AES.encrypt(webConfig.pessWebsite, webConfig.passPhrase);
const pessAllowAcessToken = CryptoJS.AES.encrypt(webConfig.allowAccess, webConfig.passPhrase);
const pessPublicToken = CryptoJS.AES.encrypt(webConfig.publicToken, webConfig.passPhrase);

export const axiosInstance = axios.create({
  baseURL: isWebConfigOn(webConfig.isDev) ? webConfig.pessLocal : webConfig.insuranceBaseUrl,
  headers: {
      "Authorization": `Bearer ${webToken}`,
      "Pess-Origin": `${pessOrigin}`,
      "Pess-Allow-Access": `${pessAllowAcessToken}`,
      "Pess-Public-Token": `${pessPublicToken}`
  }
});