import React from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useMediaQueryContext} from "../../utils/MediaQueryProvider";
import {Redirect, withRouter} from "react-router-dom";
import {useStepsContext} from "../../utils/StepsProvider";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
//@ts-ignore
import ReactSteps from "rc-steps";

const Steps = React.memo((props: any) => {
  const {getFirst, getStepById} = useStepsContext() as any;
  const {mobileView} = useMediaQueryContext() as any;
  const id = props.match.params.id;
  const name = props.match.params.name;
  const step = getStepById(id);
  
  if (!id || !step) {
      return <Redirect to={`/${props.match.params["name"]}/${getFirst()}`}/>
  }
  
  return (
    <div className={`home-page-non-title`}>
      <section id="calc">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section className="section-white">
                    <section className="section-osago">
                      <p className="subtitle">Більше ніяких папірців, поліс на e-mail <span className="subtitle-green">за 5 хвилин</span></p>
                    {!mobileView &&
                      <ReactSteps labelPlacement="vertical" direction={"horizontal"} current={step - 1}>
                        <ReactSteps.Step title="Введіть дані автомобіля"/>
                        <ReactSteps.Step title="Виберіть пропозицію"/>
                        <ReactSteps.Step title="Виберіть документ"/>
                        <ReactSteps.Step title="Заповніть документ"/>
                        <ReactSteps.Step title="Сплачуйте онлайн"/>
                      </ReactSteps>}
                      {step === 1 && <StepOne type={name}/>}
                      {step === 2 && <StepTwo type={name}/>}
                      {step === 3 && <StepThree type={name}/>}
                      {step === 4 && <StepFour type={name}/>}
                      {step === 5 && <StepFive type={name}/>}
                    </section>
              </section>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </section>
    </div>
  )
});

export default withRouter(Steps);
