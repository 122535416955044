import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundComponent = () => {
    const history = useHistory();

    useEffect(() => {
      const redirectToMainPage = () => {
        history.push("/");
      };
      
      redirectToMainPage();
    }, [history]);

    return (
        <div></div>
    );
};

export default NotFoundComponent;