import { RECEIVED } from './someTypes';

export type SomeState = {
    someData: any;
};

const initialState: SomeState = {
    someData: null
};


export default function reducer(state: SomeState = initialState, action: any) {
  switch (action.type) {
    case RECEIVED: {
        return Object.assign({}, state, {
            someData: action.someData
        });
    }
    default:
      return state;
  }
}
