import React from "react";
import CustomField from "./CustomField";

const idCard = (name: string) => `idPassport.${name}`;

const IDCardPartForm = (props) => {
    return (
      <>
        <div className={props.seriesClass}>
          <CustomField register={props.register({
            required: "Це поле обов\'язкове",
            pattern: {
              value: /^\d{9}$/,
              message: "Номер складається з 9 цифр"
            },
            maxLength: {
              value: 9,
              message: "Номер складається з 9 цифр"
            }
          })} errors={props.errors} name={idCard("number")} label="Документ №" defaultValue={props.documentSeriesValue} placeholder="123456789" readOnly={props.isReadOnly} onChange={props.onDocumentSeriesChange} />
        </div>
        <div className={props.numberClass}>
          <CustomField register={props.register({
            required: "Це поле обов\'язкове",
            pattern: {
              value: /\d{8}[-]\d{5}/,
              message: "Номер складається з 14 символів включно з -"
            },
            maxLength: {
              value: 14,
              message: "Номер складається з 14 символів включно з -"
            }
          })} errors={props.errors} name={idCard("record")} label="Запис №"
          placeholder="12345678-12345" defaultValue={props.documentNumberValue} readOnly={props.isReadOnly} onChange={props.onDocumentNumberChange} />
        </div>
        <div className={props.issuedByClass}>
          <CustomField register={props.register({
            required: "Це поле обов\'язкове",
            pattern: {
              value: /\d{4}/,
              message: "Повино бути 4 цифр"
            },
            maxLength: {
              value: 4,
              message: "Номер складається з 4 цифр"
            }
          })} errors={props.errors} name={idCard("issued_by")} label="Орган, що видав" placeholder="1234" readOnly={props.isReadOnly} onChange={props.onDocumentIssuedByChange} defaultValue={props.documentIssuedByValue} />
        </div>
        <div className={props.issuedDateClass}>
          <CustomField
            register={props.register({
              required: "Це поле обов\'язкове"
            })}
            errors={props.errors}
            name={idCard("date")}
            label="Дата видачі"
            type="date"
            placeholder="РРРР.ММ.ДД"
            autoComplete="off"
            dateReadOnly={props.isReadOnly}
            dateValue={props.documentIssuedDateValue}
            onDateChange={props.onDocumentIssuedDateChange}
          />
        </div>
        <div className={props.expirationDateClass}>
          <CustomField
            register={props.register({
              required: "Це поле обов\'язкове"
            })}
            errors={props.errors}
            name={idCard("date_end")}
            label="Дата закінчення"
            type="date"
            placeholder="РРРР.ММ.ДД"
            autoComplete="off"
            dateReadOnly={props.isReadOnly}
            dateValue={props.documentExpirationDateValue}
            onDateChange={props.onDocumentExpirationDateChange}
          />
      </div>
      </>
    )
};

export default IDCardPartForm;
