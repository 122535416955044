import React, {useEffect, useState} from "react";
import useReactRouter from "use-react-router";
import {useStepsContext} from "../../utils/StepsProvider";
import {getInsuranceType, isSettingItemActive, checkAuthToken, checkGreencardINGOCompany, filterDiiaDocuments, filterEuaDocuments} from "../../utils/utilsGeneral";
import DataOrder from "./DataOrder";
import {useDispatch, useSelector} from "react-redux";
import {setItemId} from "../../redux-data/insurance/insuranceReducer";
import {getOfferData, setData, setOfferDocumentServiceSelected, setOfferDocumentType} from "../../redux-data/offer/offerReducer";
import useForm from "react-hook-form";
import {IOfferData} from "../../redux-data/offer/offerTypes";
import {getCashback, getDiscountPercentage, getPolicyId, getPrivilege, getSelectedAmountItem, getSelectedItem} from "../../redux-data/insurance/insuranceSelector";
import {diiaDocuments, euaDocuments, privilegeTypes} from "../../mock-data/insuranceTypes";
import webConfig from "../../config";
import CustomTooltip from "../Forms/common/CustomTooltip";
import {getUserPrivilegeType} from "../../redux-data/user/userSelector";
import CustomSelect from "../common/CustomSelect";
import {setPrivilegeType, getCurrentUser, getCurrentUserInfo} from "../../redux-data/user/userReducer";

const StepThree = ({type}: {type: "epolicy" | "greencard"}) => {
  const dataInsurance = (useSelector(getOfferData) || {}) as IOfferData;
  const policy = useSelector(getSelectedItem);
  const policyAmount = useSelector(getSelectedAmountItem);
  const policyFranchiseID = useSelector(getPolicyId);
  const isPrivilege = useSelector(getPrivilege);
  const userPrivilegeType = useSelector(getUserPrivilegeType);
  const currentUser = useSelector(getCurrentUser);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const {goTo} = useStepsContext() as any;
  const {match} = useReactRouter();
  const currentInsurance = getInsuranceType(match.params["name"]);
  const dispatch = useDispatch();
  const [policySelectedDocument, setPolicySelectedDocument] = useState("");
  const [errorPrivilege, setErrorPrivilege] = useState(false);
  const [privilegeTypeError, setPrivilegeTypeError] = useState(false);
  const [isDiiaDocumentSelected, setDiiaDocumentSelected] = useState(false);
  const [isEuaDocumentSelected, setEuaDocumentSelected] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isDiiaVisible, setDiiaVisible] = useState(false);

  useEffect(() => {
      const checkAdminRights = () => {
          if (checkAuthToken()) {
              if (currentUser && currentUserInfo) {
                  if (currentUser.sessionId && currentUserInfo?.permissions.salePointAdmin) {
                      setAdmin(true);
                  } else {
                      setAdmin(false);
                  }
              } else {
                 setAdmin(false);
              }
          } else {
              setAdmin(false);
          }
      };

      const checkDiiaVisiblity = async () => {
          const diiaState = await isSettingItemActive(webConfig.displayDiiaID);
          setDiiaVisible(diiaState);
      };

      checkAdminRights();
      checkDiiaVisiblity();
  }, []);

  const {register, getValues} = useForm<IOfferData>({
    reValidateMode: "onChange",
    mode: "onChange",
    nativeValidation: false,
    defaultValues: dataInsurance
  });
  
  const handleStepBack = () => {
    goTo(2);
  };

  const handleChangePolicy = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(2);
  };

  const handleChangeDataOrder = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(1);
  };

  const discountPercentage = getDiscountPercentage(policyAmount!.amount, policyAmount!.discountedAmount);
  const diiaCustomerDocs = checkGreencardINGOCompany(type, policyFranchiseID) ? [diiaDocuments[2]] : filterDiiaDocuments(type);
  const euaCustomerDocs = checkGreencardINGOCompany(type, policyFranchiseID) ? [euaDocuments[3]] : filterEuaDocuments(type);
  const classPrivilege = !userPrivilegeType && errorPrivilege ? "form-group form-group-error" : "form-group";
  
  const handleDocumentChange = (document) => {
    const selectedDocument = document.target.value;
    setPolicySelectedDocument(selectedDocument);
    dispatch(setOfferDocumentType(selectedDocument));
    goTo(4);
  };

  const handlePrivilegeTypeChange = (type) => {
    dispatch(setPrivilegeType(type));
    setErrorPrivilege(false);
    setPrivilegeTypeError(false);
    setPolicySelectedDocument(type.value);
    dispatch(setOfferDocumentType(type.value));
    dispatch(setOfferDocumentServiceSelected("EUA"));
    goTo(4);
  };

  const handleDiiaDocSelection = () => {
    setPolicySelectedDocument("");
    setEuaDocumentSelected(false);
    setDiiaDocumentSelected(!isDiiaDocumentSelected);
    dispatch(setOfferDocumentServiceSelected("DIIA"));
  };

  const handleEuaDocSelection = () => {
    setPolicySelectedDocument("");
    setDiiaDocumentSelected(false);
    setEuaDocumentSelected(!isEuaDocumentSelected);
    dispatch(setOfferDocumentServiceSelected("EUA"));
  };

  return (
    <>
       <div className="row pay-choose pay-policy">
          <div className="col-sm-8">
            <h4>Виберіть документ для договору страхування - Електронна {currentInsurance.label}</h4>
            <div className="pay-policy_form">
              <div className="select-policy-document">
                {isPrivilege ?
                  <div className="col-md-12">
                    <div className={classPrivilege}>
                      <label>Тип пільги</label>
                      <CustomSelect
                        options={privilegeTypes}
                        handleChange={handlePrivilegeTypeChange}
                        value={userPrivilegeType}
                        isCreatable={false}
                        isSearchable={false}
                        placeholder="Тип пільги"
                        isError={privilegeTypeError}
                      />
                    </div>
                  </div>
                 :
                  <>
                    {
                      <>
                      {(isDiiaVisible || isAdmin) &&
                        <div className="diia-document-wrapper">
                            <div className="diia-document-container">
                                <CustomTooltip msg="Виберіть документ через застосунок Дія">
                                    <div className="diia-btn" onClick={handleDiiaDocSelection}>
                                        Вибрати документ через <img src={require("../../assets/img/logos/diia.svg")} alt="diiaDocumentSelection" />
                                    </div>
                                </CustomTooltip>
                            </div>
                            {isDiiaDocumentSelected &&
                              <>
                                <div className="policy-document-title">
                                  <h3>Виберіть документ, який маєте у застосунку Дія</h3>
                                </div>
                                <div className="document-type-wrapper">
                                  {diiaCustomerDocs.map((document) => (
                                    <div className="policy-document-type" key={document.value}>
                                      <div className="form-group form-radio">
                                        <input
                                          onChange={handleDocumentChange}
                                          type="radio"
                                          id={document.value}
                                          value={document.value}
                                          checked={document.value === policySelectedDocument}
                                          hidden
                                          disabled={document.disabled}
                                          name="documentType"
                                          ref={register}
                                        />
                                        <label htmlFor={document.value}>{document.label}</label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                            </>
                          }
                       </div>
                      }
                      <div className="document-wrapper">
                        <div className="document-container">
                            <CustomTooltip msg="Виберіть документ через сайт">
                                <div className="pess-btn" onClick={handleEuaDocSelection}>
                                    Вибрати документ через <img src={require("../../assets/img/logos/pess_icon.webp")} alt="euaDocumentSelection" />
                                </div>
                            </CustomTooltip>
                        </div>
                        {isEuaDocumentSelected &&
                          <>
                            <div className="policy-document-title">
                              <h3>Виберіть документ, який Ви маєте</h3>
                            </div>
                            <div className="document-type-wrapper">
                              {euaCustomerDocs.map((document) => (
                                <div className="policy-document-type" key={document.value}>
                                  <div className="form-group form-radio">
                                    <input
                                      onChange={handleDocumentChange}
                                      type="radio"
                                      id={document.value}
                                      value={document.value}
                                      checked={document.value === policySelectedDocument}
                                      hidden
                                      disabled={document.disabled}
                                      name="documentType"
                                      ref={register}
                                    />
                                    <label htmlFor={document.value}>{document.label}</label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        }
                      </div>
                     </>
                    }
                 </>
                }
               </div>
               <div className="policy-controls">
                  <button type="button" className="insurance-btn" onClick={handleStepBack}><i className="fa-regular fa-arrow-left notification-icon"></i>Назад</button>
               </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pay-choose_info">
              <DataOrder type={type} handleChangeDataOrder={handleChangeDataOrder} />
              <div className="pay-choose_info-block">
                <p className="subtitle green">Поліс</p>
                <ul className="pay-choose_info-list">
                  <li>
                    <p>Назва поліса</p>
                    <div className="font-bold">{policy && policy.name}</div>
                  </li>
                  <li>
                    <p>Франшиза</p>
                    <div className="font-bold">{policyAmount && policyAmount!.franchise} грн</div>
                  </li>
                  <li>
                    <p>Вартість</p>
                    {policyAmount && policyAmount.amount > policyAmount.discountedAmount ? 
                      <div className="font-bold">
                        <div className="old-price">{policyAmount.amount.toFixed(2)} грн</div>
                      </div>
                      : 
                      <div className="font-bold">{policyAmount!.amount.toFixed(2)} грн</div>
                    }
                  </li>
                  {policyAmount && policyAmount.amount > policyAmount.discountedAmount &&
                    <>
                      <li>
                        <p>До оплати</p>
                        <div className="font-bold">{policyAmount!.discountedAmount.toFixed(2)} грн</div>
                      </li>
                      <li>
                        <p>Знижка</p>
                        <div className="font-bold">{discountPercentage.toFixed(0)}%</div>
                      </li>
                    </>
                  }
                  {
                    type === "greencard" && policyAmount?.isCashback &&
                    <>
                      <li>
                        <p>Кешбек</p>
                        <div className="font-bold">{policyAmount.cashbackPercentage.toFixed(0)}%</div>
                      </li>
                      <li>
                        <p>Сума до повернення на Вашу карту</p>
                        <div className="font-bold">{getCashback(policyAmount.amount, policyAmount.cashbackPercentage).toFixed(2)} ₴</div>
                      </li>
                    </>
                  }
                </ul>
                <button onClick={handleChangePolicy} className="btn btn-link btn-edit">
                  Змінити
                </button>
              </div>
            </div>
          </div>
       </div>
    </>
  );
};

export default StepThree;