import someData, {SomeState} from "./some/someReducer";
import insurance from "./insurance/insuranceReducer";
import {combineReducers} from "redux";
import tokenReducer from "./token/tokenReducer";
import offerReducer from "./offer/offerReducer";
import {IVehicleState} from "./vehicle/vehicleTypes";
import vehicleReducer from "./vehicle/vehicleReducer";
import {IOfferState} from "./offer/offerTypes";
import {IDataOrderState, IGreenCardDataOrderState, IPolicyState} from "./insurance/insuranceTypes";
import cityReducer from "./city/cityReducer";
import {ICityState} from "./city/cityTypes";
import userReducer from "./user/userReducer";
import {IUserInfoState} from "./user/userType";

export interface RootState {
  someData: SomeState;
  insurance: {
    dataOrder: IDataOrderState;
    greenCardDataOrder: IGreenCardDataOrderState;
    policies: IPolicyState;
  },
  tokens: {
    token: string;
    error: any;
  },
  vehicle: IVehicleState;
  city: ICityState;
  offer: IOfferState;
  user: IUserInfoState;
}

export default combineReducers({
  someData,
  insurance,
  tokens: tokenReducer,
  vehicle: vehicleReducer,
  city: cityReducer,
  offer: offerReducer,
  user: userReducer
});
